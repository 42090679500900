export default {
	translation: {
		quantity: 'Количество',
		changeDescription: 'Изменить описание',
		noStatus: 'Без статуса',
		watchAll: 'Смотреть все',
		performanceIndicator: 'КПЭ',
		change: 'Изменить',
		time: 'Время',
		mine: 'Мои',
		show: 'Показать',
		nobody: 'Никто',
		recordNotFound: 'Запись с таким id не найдена',
		typeText: 'Введите текст',
		recover: 'Восстановить',
		code: 'Код',
		pickDate: 'Выберите дату',
		appSettings: 'Настройки приложения',
		online: 'В сети',
		consumption: 'Расход',
		open: 'Открыть',
		any: 'Любой',
		deleteDescription: 'Вы точно хотите удалить запись? Отменить данное действие будет невозможно',
		article: 'Артикул',
		selectAll: 'Выбрать все',
		clearAll: 'Убрать все',
		overdueNotClosed: 'Просрочено (не закрыты)',
		overdueNotClosedPopover: 'Количество просроченных задач, находящихся в работе',
		completedTasksPopover: 'Общее количество, завершенных задач в выбранном периоде',
		overdueClosed: 'Просрочено (закрыты)',
		overdueClosedPopover: 'Количество задач, завершенных не вовремя в выбранном периоде',
		notOverdue: 'Не просрочено',
		mainInfo: 'Основная информация',
		dueDateNow: 'Истекает сегодня',
		dueDateNowPopover: 'Количество задач, срок выполнения которых истекает сегодня',
		perfomanceAllTaskPopover: 'Количество задач, созданных в выбранном периоде',
		state: 'Состояние',
		responsible: 'Ответственный',
		responsibles: 'Ответственные',
		selectLists: 'Назначить состояния',
		searchName: 'Поиск по наименованию',
		searchNameAndNumber: 'Поиск по названию и номеру',
		searchNumber: 'Поиск по номеру',
		confirm: 'Подтвердить',
		selectTheme: 'Выбрать тему',
		address: 'Адрес',
		noSelectedLists: 'У вас нету списка состояния задач',
		selected: 'Выбрано',
		pickFromList: 'Выберите из списка',
		chart: 'График',
		select: 'Выберите из списка',
		export: 'Экспорт',
		author: 'Автор',
		import: 'Импорт',
		exportCSV: 'Экспорт CSV',
		importCSV: 'Импорт CSV',
		exportTXT: 'Экспорт TXT',
		importTXT: 'Импорт TXT',
		exportXLSX: 'Экспорт XLSX',
		clean: 'Сбросить',
		actions: 'Действия',
		noProjects: 'Не заданы проекты',
		all: 'Все',
		day: 'День',
		week: 'Неделя',
		currentWeek: 'Текущая неделя',
		weeks: 'Недели',
		monthes: 'Месяцы',
		complited: 'Выполнено',
		uncomplited: 'Не выполнено',
		currentMonth: 'Текущий месяц',
		month: 'Месяц',
		year: 'Год',
		quarter: 'Квартал',
		byQuarter: 'По кварталам',
		halfyear: 'Пол года',
		from: 'От',
		to: 'до',
		periodFrom: 'Период с',
		periodTo: 'Период по',
		defaultTitle: 'SmartERP',
		name: 'Название',
		number: 'Номер',
		fio: 'ФИО',
		members: 'Участники',
		unnamed: 'Без названия',
		type: 'Тип',
		firstName: 'Имя',
		lastName: 'Фамилия',
		middleName: 'Отчество',
		amount: 'Количество',
		fullName: 'Ф.И.О.',
		doubleClickToEdit: 'Двойной щелчок для редактирования',
		description: 'Описание',
		fact: 'Факт',
		addDescription: 'Добавить описание',
		add: 'Добавить',
		finance: 'Финансы',
		loading: 'Загрузка...',
		information: 'Информация',
		toAttachFile: 'Прикрепить файл',
		attachments: 'Прикрепленные файлы',
		files: 'Файлы',
		uploadFiles: 'Загрузить файлы',
		deleteFile: 'Удалить файл',
		deleteCategory: 'Удалить категорию?',
		uploadingFiles: 'Загружаемые файлы',
		uploadError: 'Ошибка загрузки',
		uploadingFile: 'Загрузка файла',
		uploadSuccess: 'Загрузка завершена',
		rejectedFiles: 'Загрузка запрещена',
		selectedCount: 'Выбрано элементов',
		dragDropHint: 'Перетащите сюда файлы или нажмите чтобы выбрать файлы',
		editHint: 'Двойной клик для редактирования',
		editHintMobile: 'Долгий тап для редактирования',
		management: 'Управление',
		sum: 'Сумма',
		tax: 'Налог',
		profit: 'Прибыль',
		income: 'Приход',
		costs: 'Расход',
		startRemains: 'Остаток на начало',
		endRemains: 'Остаток на конец',
		date: 'Дата',
		rub: '₽',
		administration: 'Администрирование',
		admin: 'Админ',
		usersAndRoles: 'Пользователи и права',
		settings: 'Настройки',
		percent: 'Процент',
		percentChar: '%',
		price: 'Цена',
		paid: 'Оплачено',
		phone: 'Телефон',
		create: 'Создать',
		search: 'Поиск',
		more: 'еще',
		moreEllipsis: 'Еще...',
		next: 'дальше',
		nextEllipsis: 'Дальше...',
		notFound: 'Не найдено',
		nothingFound: 'Ничего не найдено',
		edit: 'Редактировать',
		endEdit: 'Завершить редактирование',
		save: 'Сохранить',
		saving: 'Сохранение...',
		saved: 'Сохранено',
		savedAt: 'Сохранено в',
		cancel: 'Отмена',
		delete: 'Удалить',
		clear: 'Очистить',
		confirmDelete: 'Удалить?',
		deleting: 'Удаление...',
		deleted: 'Удалено',
		updatedAt: 'Обновлено',
		error: 'Ошибка',
		ok: 'ОК',
		yes: 'Да',
		no: 'Нет',
		projects: 'Проекты',
		board: 'Задачи',
		agent: 'Агент',
		clients: 'Клиенты',
		persons: 'Персоны',
		noSelected: 'не выбрано',
		noCreate: 'создание объекта здесь не разрешено',
		payments: 'Платежи',
		paymentInfo: 'Платежные реквизиты',
		realm: 'Область',
		status: 'Статус',
		color: 'Цвет',
		image: 'Изображение',
		avatar: 'Аватар',
		notSet: 'Не задано',
		priority: 'Приоритет',
		created: 'Создано',
		gb: 'ГБ',
		confirmPopup: {
			message: 'Введенные данные будут удалены. Подтвердить?',
			postComment: 'Введеный комментарий будет удален. Подтвердить?',
			comment: 'Изменения в комментарии будут удалены. Подтвердить?',
		},
		telegram: {
			title: 'Телеграм',
			token: 'Токен бота',
			bot: 'Имя бота',
			proxy: 'Прокси',
			testBot: 'Проверить бота',
		},
		// modify: 'Изменить',
		modify: {
			title: 'Изменить',
			description: 'Изменить описание',
		},
		modified: 'Изменено',
		username: 'Пользователь',
		users: 'Пользователи',
		executor: 'Исполнитель',
		roles: 'Роли',
		acls: 'Права',
		perPage: 30,
		itemsPerPage: 'Кол-во на странице',
		invalidId: 'Некорректный ID',
		modelAccessDenied: 'Нет доступа к модели',
		private: 'Приватность',
		startDate: 'Начало',
		dueDate: 'Завершение',
		roleMappings: 'Назначения ролей',

		'Element does not exist': 'Объект не найден',
		'Element updated': 'Объект обновален',
		'Element created': 'Объект создан',
		'Element deleted': 'Объект удален',
		percentLeft: 'Доля компании',
		authorization: 'Авторизация',
		rewritePassword: 'Смена пароля',
		rewritePasswordHint: 'Срок действия пароля истек, смените пароль',
		login: 'Войти',
		logout: 'Выйти',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		USER_BLOCKED: 'Учетная запись заблокирована',
		NO_SESSION_CAPTCHA: 'Не сгенерирован новый код',
		EMPTY_CAPTCHA: 'Не введен код',
		INVALID_CAPTCHA: 'Неверный код',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		EMAIL_NOT_FOUND: 'Email не найден',
		Unauthorized: 'Необходимо авторизоваться',
		required: 'Необходимо заполнить поле',
		datePickerPlaceholder: 'ДД.ММ.ГГ',
		dateTimePickerPlaceholder: 'ДД.ММ.ГГ ЧЧ:ММ',
		oldContentPopover: 'Данный раздел больше не поддерживается',
		apply: 'Применить',
		offline: 'Не в сети',
		postComment: {
			placeholder: 'Введите комментарий...',
		},
		module: {
			settings: 'Настройки модуля',
			sales: {
				title: 'Продажи',
				showOldPriceList: 'Показывать старую версию прайс-листа',
				showOrderWaybill: 'Показывать накладную в заказе',
				showOrderPassport: 'Показывать паспорт в заказе',
				showOrderOldPositions: 'Показывать старые позиции заказа',
				showOrderTechMap: 'Показывать технологическую карту заказа',
				showLoginNotifications: 'Оповещения входа пользователей в приложение',
				showOrderCustomFields: 'Настраиваемые поля заказа',
			},
			manufacture: {
				title: 'Производство',
				defaultTechnologist: 'Технолог по умолчанию',
				enableCalibrationQuantity: 'Колибровочное количество материала (приладка)',
			},
			management: {
				title: 'Менеджемент',
				showTaskCustomFields: 'Включить настраиваемые поля задач',
				showProjectCustomFields: 'Включить настраиваемые поля проекта',
			},
			admin: {
				title: 'Администрирование',
			},
			documents: {
				title: 'Докменты',
			},
			contragents: {
				title: 'Контрагенты',
			},
			finance: {
				title: 'Финансы',
			},
			storehouse: {
				title: 'Склад',
			},
			messages: {
				title: 'Сообщения',
			},
		},
		feedback: {
			title: 'Форма обратной связи',
			new: 'Новое обращение',
			phone: 'Номер телефона',
			email: 'Электронная почта',
			subject: 'Тема письма',
			send: 'Отправить',
			description: 'Описание',
			sent: 'Сообщение успешно отправлено!',
			invalidEmail: 'Некорректный E-mail',
		},
		states: {
			new: 'Новое состояние',
		},
		parameters: {
			new: 'Новый параметр',
		},
		dashBoard: 'Главная',
		datePeriod: {
			select: 'Выберите период',
			specified: 'Произвольный период',
			currentMonth: 'Текущий месяц',
			currentQuarter: 'Текущий квартал',
			currentYear: 'Текущий год',
			lastMonth: 'Прошлый месяц',
			lastQuarter: 'Прошлый квартал',
			lastYear: 'Прошлый год',
			fiveYears: '5 лет',
			all: 'Всё время',
			year: 'Год',
			month: 'Месяц',
			quarter: 'Квартал',
			day: 'День',
			week: 'Неделя',
			halfDay: '12 часов',
			quarterDay: '6 часов',
			hour: 'Час',
			comparisonPeriod: 'Период сравнения',
			scale: 'Масштаб',
		},
		checklist: {
			new: 'Новый чек-лист',
			hintEmpty: 'Незаполненные пункты удаляются при сохранении',
			hintDrag: 'Пункты можно менять местами',
			plural: 'Чек-листы',
			add: 'Добавить чек-лист',
			addPosition: 'Добавить пункт',
			name: 'Название чек-листа',
			typeChecklistName: 'Введите название чек-листа',
			typeChecklistPositionName: 'Введите пункт',
			delete: 'Удалить чек-лист?',
			title: 'Чек-лист — это инструмент, который помогает систематизировать и контролировать выполнение задач',
		},
		auth: {
			TOKEN_ROTTEN: 'Срок действия токена истек',
			title: 'Авторизация',
			login: 'Вход в аккаунт',
			logout: 'Выйти',
			goBack: 'Назад',
			register: 'Зарегистрироваться',
			restore: 'Восстановление пароля',
			next: 'Далее',
			email: 'E-mail',
			lastName: 'Фамилия',
			firstName: 'Имя',
			middleName: 'Отчество',
			password: 'Пароль',
			order: 'Получить доступ',
			enterEmail: 'Введите E-mail',
			enterLogin: 'Введите логин',
			enterPassword: 'Введите пароль',
			repeatPassword: 'Повторите пароль',
			enterRepeatedPassword: 'Повторите пароль',
			repeat: 'Повторите',
			minPasswordLength: 'Минимальная длина пароля',
			invalidEmail: 'Некорректный E-mail',
			emailExists: 'Такой E-mail уже зарегистрирован',
			registrationSuccess: 'На ваш E-mail ($email) была выслана ссылка для подтверждения регистрации.',
			noMatch: 'Пароли не совпадают',
			enterCode: 'Введите код',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
			limitExceeded: 'Превышен лимит попыток',
			tryAgain: 'Введите логин / пароль',
			registration: 'Регистрация',
			captcha: 'Код с картинки',
			simplePassword: 'Пароль не соответствует требованиям',
		},
		record: {
			saved: 'Запись сохранена',
			deleted: 'Запись удалена',
			recordsUpdated: 'Записи были обновлены',
		},

		config: {
			saved: 'Настройки сохранены',
			blockedFieldsMessage: 'Заблокированные поля прописаны в конфиге сервера',
			modules: 'Модули',
		},
		mail: {
			title: 'Почта',
			user: 'Имя почтового ящика',
			pass: 'Пароль',
			host: 'Адрес SMTP сервера, с поддержкой TSL или SSL',
			port: 'Порт SMTP сервера',
		},
		reset: {
			title: 'Сброс данных',
			resetData: 'Сбросить данные',
			resetConfirm: 'Вы уверены, что хотите сбросить все данные? Действие нельзя отменить.',
			error: 'Ошибка',
			errorDesc: 'Произошла неизвестная ошибка',
			success: 'Успешно',
			successDesc: 'Данные успешно удалены!',
			warning: 'Предупреждение',
		},
		placeholder: {
			search: 'Поиск...',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
			sendTestEmail: 'Отправить тестовое письмо',
			sended: 'Письмо отправлено',
		},

		sign: {
			in: 'Авторизация',
			out: 'Выход',
		},
		password: {
			changed: 'Пароль изменен',
			hint: 'Пароль...',
			field: 'Пароль',
			input: 'Введите пароль',
			repeat: 'Повторите пароль',
			newPasswordHint: 'Оставьте это поле пустым, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают',
			match: 'Пароли совпадают',
			new: 'Новый пароль',
			validation: {
				minLength: 'Минимальная длина пароля: {{length}}',
				requireNumber: 'Наличие цифр',
				requireUppercase: 'Наличие заглавных символов',
				requireLowercase: 'Наличие строчных символов',
				requireSpecialChars: 'Наличие специальных символов',
				passwordOld: 'Введите старый пароль',
			},
			old: 'Старый пароль',
		},
		editor: {
			enterURL: 'Введите URL:',
		},
		document: {
			title: 'Документ',
			new: 'Новый документ',
			plural: 'Документы',
			all: 'Все документы',
			moduleTitle: 'Документооборот',
			search: 'Фильтр + поиск по наименованию',
			total: 'Всего документов',
			totalSum: 'Сумма',
			card: {
				name: 'Наименование',
				number: 'Номер',
				type: 'Тип',
				status: 'Статус',
				contragents: 'Контрагенты',
				labels: 'Метки',
				responsible: 'Ответственный',
				totalSum: 'Сумма',
				project: 'Проект',
				addName: 'Введите название документа',
				addNumber: 'Введите номер',
				addSum: 'Введите сумму',
				clientNotFound: 'Клиент не найден',
				tabs: {
					info: 'Общая информация',
					projects: 'Проекты',
					orders: 'Заказы',
					payments: 'Платежи',
					members: 'Участники',
				},
				startDate: 'Дата документа',
				endDate: 'Дата закрытия',
				order: 'Заказ',
			},
			emptyOrders: 'Не найдено заказов для данного документа',
			private: 'Приватность',
			notFound: 'Документ не найден',
			document_one: 'документ',
			document_few: 'документа',
			document_many: 'документов',
			calendarHint: 'Документы без назначенной даты закрытия',
		},
		documentType: {
			title: 'Тип документа',
			plural: 'Типы документов',
			new: 'Новый тип документа',
			delete: 'Удалить тип',
		},
		documentStatus: {
			title: 'Статус документа',
			plural: 'Статусы документов',
			new: 'Новый статус документа',
			delete: 'Удалить статус',
		},
		category: {
			new: 'Новая категория',
			total: 'Всего категорий',
			title: 'Категория',
			plural: 'Категории',
			name: 'Название',
			parent: 'Родительская категория',
			priority: 'Приоритет',
			priorityHint: 'Чем больше значение приоритета, тем выше отображается категория в списке',
		},
		project: {
			isLimitedTaskPropertiesHint:
				'По умолчанию у задачи будут все дополнительные поля. В ограниченном режиме только выбранные в проекте',
			isLimitedTaskProperties: 'Режим ограниченного списка дополнительных полей задач',
			taskCustomFields: 'Дополнительные поля задач',
			noListsMessage: 'У проекта {{projectName}} не добавлены статусы задач',
			goBack: 'Вернуться назад к проектам',
			select: 'Выберите проект из списка',
			creator: 'Автор проекта',
			title: 'Проект',
			plural: 'Проекты',
			createNew: 'Создать новый проект',
			created: 'Проект создан:',
			addRole: 'Добавить участника',
			addTask: 'Добавить задачу',
			dueDate: 'Дедлайн проекта',
			labels: 'Метки проекта',
			name: 'Название проекта',
			addName: 'Введите название проекта',
			description: 'Описание проекта',
			projectList: 'Состояние проекта:',
			projectListShort: 'Состояние',
			tasks: 'Задачи',
			lists: 'Состояния задач',
			members: 'Участники',
			history: 'История проекта',
			info: 'Информация',
			deleteMsg: 'Вы собираетесь удалить проект! Вы уверены?',
			notFound: 'Проект не найден',
			listMode: 'Список',
			kanbanMode: 'Канбан',
			count: 'Проектов',
			tasksCount: 'Задач',
			openedCount: 'Открытых',
			closedCount: 'Закрытых',
			filterHint: 'Поиск по id, названию',
			search: 'Поиск проекта',
			total: 'Всего проектов',
			mode: 'Вид',
			all: 'Все проекты',
			active: 'Активные',
			closed: 'Закрытые',
			sortByUpdated: 'Недавно обновленные',
			sortByNew: 'Сначала новые',
			owner: 'Проект создал(а)',
			searchByMember: 'Поиск по участнику',
			delete: 'Удалить проект',
			startDate: 'Начало проекта',
			new: 'Новый проект',
			taskList: 'Состояния задач',
			memberExist: 'Такой пользователь уже является участником проекта. Обновите страницу',
			mine: 'Мои проекты',
			tasksFilter: {
				startDate: 'Дата от',
				endDate: 'Дата до',
			},
		},
		client: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			create: 'Новый контрагент',
			personName: 'Контактное лицо',
			personRole: 'Должность',
			contacts: 'Контактные лица',
			addContact: 'Добавить',
			orders: 'Заказы контрагента',
			removeContact: 'Убрать из контактных лиц контрагента?',
			removeContactWarning:
				'Убрать из контактных лиц контрагента?\nЭто также уберет этот контакт из всех заказов этого контрагента.\nЗаказов с этим контактом: ',
			noContacts: 'Контактные лица не найдены',
			onlyAvailable: 'Только контактные лица контрагента',
			total: 'Всего контрагентов',
		},
		contact: {
			searchHint: 'Поиск по Ф.И.О. / логину / email',
			new: 'Новый контакт',
			emptyName: 'Заполните хотя бы одно поле (фамилия / имя / отчество / логин)',
			add: 'Добавить контактное лицо',
		},
		person: {
			title: 'Персона',
			plural: 'Персоны',
			create: 'Новая персона',
			choose: 'Выберите персону',
		},
		payment: {
			title: 'Платеж',
			plural: 'Платежи',
			contragent: 'Контрагент',
			date: 'Дата оплаты',
			total: 'Сумма',
			payer: 'Отправитель',
			recipient: 'Получатель',
			vat: 'Сумма НДС',
			vatRate: 'Ставка НДС',
			payerText: 'Текст отправителя',
			recipientText: 'Текст получателя',
			selectProject: 'Выберите проект',
			selectPayer: 'Выберите отправителя',
			selectRecipient: 'Выберите получателя',
			selectBankAccount: 'Выберите счёт',
			selectCashFlow: 'Выберите статью',
			selectCurrency: 'Выберите валюту',
			selectContragent: 'Выберите контрагента',
			selectDirection: 'Выберите направление',
			allContragents: 'Все контрагенты',
			allDirections: 'Все направления',
			selectedContragents: 'Выбрано контрагентов',
			selectedBankAccounts: 'Выбрано счетов',
			selectedCashFlows: 'Выбрано статей',
			selectedProjects: 'Выбрано проектов',
			selectedDirections: 'Выбрано направлений',
			direction: 'Направление',
			bankAccount: 'Счет',
			recipientBankAccount: 'Банковский счет получателя',
			payerBankAccount: 'Банковский счет отправителя',
			recipientBank: 'Банк получателя',
			payerBank: 'Банк отправителя',
			number: 'Номер',
			paymentId: 'Идентификатор',
			purpose: 'Назначение',
			priority: 'Приоритет',
			responsiblePerson: 'Ответственное лицо',
			providerStatus: 'Статус составителя',
			KBKindicator: 'Показатель КБК',
			okato: 'ОКАТО',
			baseIndicator: 'Показатель основания',
			periodIndicator: 'Показатель периода',
			numberIndicator: 'Показатель номера',
			dateIndicator: 'Показатель даты',
			typeIndicator: 'Показатель типа',
			budgetTransfer: 'Перечисления в бюджет',
			paid: 'Оплачено',
			contract: 'Договор',
			allBankAccounts: 'Все счета',
			allCashFlows: 'Все статьи',
			allProjects: 'Все проекты',
			income: 'Приход',
			costs: 'Расход',
			transfer: 'Перемещение',
			turnover: 'Оборот',
			profit: 'Прибыль',
			addIncome: 'Добавить операцию дохода',
			addCosts: 'Добавить операцию расхода',
			addTransfer: 'Добавить перевод',
			add: 'Добавить новую операцию',
			edit: 'Карточка операции',
			transferCashFlow: 'Перевод между счетами',
			plan: 'Плановые',
			fact: 'Фактические',
			balance: 'Баланс',
			all: 'Все',
			bankAccountFrom: 'Со счета',
			bankAccountTo: 'На счет',
			billingDate: 'Дата начисления',
			splitTotal: 'Разбить платеж',
			delete: 'Удалить операцию',
			costsTotal: 'Затраты итого',
			incomeTotal: 'Выручка итого',
		},
		contract: {
			title: 'Договор',
			plural: 'Договора',
			date: 'Дата',
			contragent: 'Контрагент',
			organization: 'Оргиназиация',
			name: 'Наименование',
			number: 'Номер',
			contractType: 'Тип',
			conventionalUnit: 'Расчеты в условных единицах',
		},
		projectRole: {
			title: 'Роль в проекте',
			plural: 'Роли в проектах',
			short: 'Роль',
			new: 'Новая роль в проекте',
			choose: 'Выберите роль в проекте',
			delete: 'Удалить роль',
		},
		user: {
			create: 'Новый пользователь',
			allExecutors: 'Все исполнители',
			allUsers: 'Все пользователи',
			selectedExecutors: 'Выбрано исполнителей',
			selectedUsers: 'Выбрано пользователей',
			allAuthors: 'Все авторы',
			selectedAuthors: 'Выбрано авторов',
			allMembers: 'Все участники',
			selectedMembers: 'Выбрано участников',
			recoverEmailSended: 'На указанный Вами адрес эл.почты отправлено письмо с инструкциями для восстановления пароля',
			title: 'Пользователь',
			plural: 'Пользователи',
			new: 'Новый пользователь',
			phone: 'Телефон',
			email: 'E-mail',
			emptyEmail: 'Введите E-mail',
			invalidEmail: 'Некорректный E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			loginOrEmail: 'Логин / E-mail',
			password: 'Пароль',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			profile: 'Профиль',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал(а) задачу',
			telegram: 'Telegram ID',
			deletedUser: 'Неизвестный пользователь',
			emailAlreadyExists: 'Пользователь с таким E-mail уже существует',
			all: 'Все пользователи',
			confirmSelectBlockedUser: 'Данный пользователь заблокирован. Вы уверены?',
			isOnline: 'Статус',
			lastAuthDate: 'Дата последней авторизации',
			isBlocked: 'Заблокирован',
			block: 'Заблокировать',
			unlock: 'Разблокировать',
			transferUser: {
				title: 'Этот пользователь является автором у проектов или задач',
				isTransfer: 'Выберите пользователя, которому хотите передать авторство:',
				onTransfer: 'Передать и продолжить удаление',
			},
			statusesTitle: 'Статусы пользователя',
			statusTitle: 'Статус',
			lastActivity: 'Последняя активность',
		},
		profile: {
			enterName: 'Введите имя',
			enterLastName: 'Введите фамилию',
			enterMiddleName: 'Введите отчество',
			phone: 'Номер телефона',
			enterId: 'Введите ID',
			title: 'Настройки профиля',
			addAvatar: 'Добавление фотографии',
			selectPicture: 'Выбрать фото',
			blocked: 'Вы собираетесь заблокировать учетную запись! Вы уверены?',
			notBlocked: 'Вы собираетесь разблокировать учетную запись! Вы уверены?',
			blockedStatus: 'Причина блокировки',
			protectionNoActivity: 'Защита от блокировки по неактивности',
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			new: 'Новая роль',
			name: 'Название',
			description: 'Описание',
		},
		acl: {
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: 'Модель',
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
			search: 'Поиск',
			new: 'Новое право',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенные роли',
			create: 'Новое назначение роли',
		},
		projectMembers: 'Участники проекта',
		projectMember: {
			title: 'Участник',
			plural: 'Участники',
			create: 'Новый участник',
			choose: 'Выберите или персону или роль в проекте',
			same: 'Такой участник проекта уже есть',
			comment: 'Комментарий',
			search: 'Поиск',
		},
		labels: 'Метки',
		label: {
			title: 'Метка',
			plural: 'Метки',
			all: 'Все метки',
			new: 'Новыя метка',
			delete: 'Удалить метку',
		},
		tasks: 'Задачи',
		tasksToolbar: {
			relations: {
				user: 'Исполнитель',
				owner: 'Автор',
				members: 'Участник',
				selected: 'выбрано: {{countRelations}}',
			},
		},
		task: {
			projectName: 'Проект',
			boardListName: 'Статус',

			additionalTaskFields: 'Дополнительные поля задачи',
			notification: {
				youResponsibleThisTask: 'Вы назначены ответственным за эту задачу',
				youMentionThisTask: 'Вас упомянули в этой задаче',
				youResponsible: 'Вы назначены ответственным за задачу',
				youMention: 'Вас упомянули в задаче',
				taskChecklistUpdate: 'Чек-листы обновлены',
				taskCommentUpdate: 'Комментарии обновлены',
			},
			deleteDescription: 'Вы точно хотите удалить задачу? Отменить данное действие будет невозможно',
			ownerNotification: 'Вы назначены ответственным к задаче',
			overDue: 'Просроченные задачи',
			mine: 'Мои задачи',
			mineOverdueTasks: 'Мои просроченные задачи',
			overview: 'Обзор задач',
			otherLists: 'Другие',
			any: 'Любой',
			title: 'Задача',
			plural: 'Задачи',
			create: 'Новая задача',
			delete: 'Удалить задачу',
			confirmDelete: 'Вы уверены?',
			name: 'Название задачи',
			description: 'Описание задачи',
			changeDescription: 'Изменить описание',
			createDescription: 'Добавить описание',
			priority: 'Приоритет',
			person: 'Назначена',
			startDate: 'Начало задачи',
			dueDate: 'Дедлайн',
			assignedTo: 'Назначено',
			notAssigned: 'Не назначена',
			actions: 'Действия с задачей',
			labels: 'Метки задачи',
			updatedAt: 'Обновлено',
			closed: 'Закрытые',
			active: 'Активные',
			status: 'Состояние',
			boardList: 'Состояние задачи',
			all: 'Все',
			allAuthor: 'Любой автор',
			private: 'Приватная',
			subject: 'задачу',
			noProject: 'Задача без проекта!',
			sortByUpdated: 'Сначала новые',
			total: 'Всего задач',
			notFound: 'Задача не найдена',
			addDescription: 'Добавьте описание к задаче',
			assignedToMe: 'Назначено на меня',
			addName: 'Введите название задачи',
			iAuthor: 'Я автор',
			meTaskMember: 'Я участник задачи',
			selectedTaskMembers: 'Выбрано участников:',
			me: 'Я',
			planTime: 'Запланированное время',
			factTime: 'Фактическое время',
			createdAt: 'Дата создания',
			closedAt: 'Дата закрытия',
			members: 'Участники',
			responsible: 'Ответственный',
			task_one: 'задача',
			task_few: 'задачи',
			task_many: 'задач',
			calendarHint: 'Задачи без назначенного дедлайна',
		},
		history: {
			sectionTitle: 'История',
			title: 'История задачи',
			userHistory: 'История пользователя',
			created: 'создал(а) задачу',
			comments: 'Комментарии',
			leftComment: 'оставил(а) комментарий',
			updated: 'обновил(а)',
			appointed: 'изменил(а) ответственного за задачу на',
			appointedSelf: 'назначил(а) себя ответственным(ой) за задачу',
			appointedNull: 'убрал(а) ответственного за задачу',
			status: 'перевел(а) задачу в статус',
			priority: 'изменил(а) приоритет задачи',
			private: 'сделал(а) задачу приватной',
			public: 'сделал(а) задачу публичной',
			startDate: 'установил(а) начало задачи на',
			dueDate: 'установил(а) дедлайн на',
			initial: 'изначальная версия',
			prev: 'предыдущая версия',
			updatedTo: 'обновленная версия',
			deleteFile: 'удалил(а) файл',
			empty: 'не задано',
			nulled: 'значение удалено',
			addLabel: 'добавил(а) метку',
			removeLabel: 'удалил(а) метку',
			removeDueDate: 'убрал(а) дедлайн',
			removeStartDate: 'убрал(а) начало задачи',
			taskMemberAdded: 'добавил(а) в участники задачи ',
			projectMemberAdded: 'добавил(а) в участники проекта ',
			taskMemberSelfAdded: 'добавил(а) себя в участников задачи',
			projectMemberSelfAdded: 'добавил(а) себя в участников проекта',
			taskMemberRemoved: 'убрал(а) из участников задачи ',
			projectMemberRemoved: 'убрал(а) из участников проекта ',
			taskMemberSelfRemoved: 'убрал(а) себя из участников задачи',
			projectMemberSelfRemoved: 'убрал(а) себя из участников проекта',
			memberStatus: 'изменил(а) статус участника(цы)',
			memberStatusSelfChange: 'изменил(а) свой статус участника ',
			to: 'на',
			planTime: 'изменил(а) запланированное время',
			factTime: 'изменил(а) фактическое время',
			task: {
				project: 'изменил(а) проект на',
				checklistPosition: 'Изменил(а) статус пункта чек-листа',
				on: 'на',
			},
			project: {
				created: 'создал(а) проект',
				startDate: 'изменил(а) дату начала проекта на',
				endDate: 'изменил(а) дату конца проекта на',
				title: 'История проекта',
				name: 'изменил(а) название проекта на',
				status: 'изменил(а) статус проекта на',
				description: 'изменил(а) описание проекта на',
				contragent: 'изменил(а) контрагента на',
				taskStatus: 'изменил(а) статус задачи {{task}} на',
				appointedSelf: 'назначил(а) себя ответственным(ой) за задачу {{task}}',
				appointed: 'изменил(а) ответственного за задачу {{task}} на',
				appointedNull: 'убрал(а) ответственного за задачу {{task}}',
			},
			productionOrder: {
				title: 'История наряда',
				create: 'создал(а) наряд',
				updated: 'обновил(а) поля',
				technologist: 'изменил(а) Технолога наряда на',
				status: 'изменил(а) Статус наряда на',
				name: 'изменил(а) Название наряда на',
				comment: 'изменил(а) Комментарий на',
				amount: 'изменил(а) Количество на',
				startDate: 'изменил(а) Дату передачи на',
				endDate: 'изменил(а) Дату завершения на',
				measure: 'изменил(а) Единицу измерения на',
				warehouseTo: 'изменил(а) Склад итоговой продукции на',
				nomenclature: 'изменил(а) Номенклатуру на',
				techMap: 'изменил(а) технологическую карту на',
				techMapReset: 'очистил(а) технологическую карту и процессы',
				sendToWarehouse: 'отправил(а) готовую продукцию на склад',
				cancel: 'отменил(а) наряд',
			},
			deltaPopup: {
				old: 'Было',
				new: 'Стало',
			},
			notFound: 'Записей не найдено',
			productionOrderProcess: {
				title: 'История процессов наряда',
				create: 'создал процесс',
				executor: 'изменил(а) исполнителя у процесса "{{processName}}" на',
				money: 'изменил(а) стоимость у процесса "{{processName}}"',
				warehouse: 'изменил(а) склад у процесса "{{processName}}" на',
				equipment: 'изменил(а) оборудование у процесса "{{processName}}" на',
				workshop: 'изменил(а) цех у процесса "{{processName}}" на',
				status: 'изменил(а) статус у процесса "{{processName}}" на',
				readyTime: 'изменил(а) время готовности у процесса "{{processName}}" на',
				factTime: 'изменил(а) фактическое время у процесса "{{processName}}" на',
				updated: 'обновил(а) поля у процесса "{{processName}}"',
				process: 'выбрал(а) процесс',
				deleted: 'удалил(а) процесс',
				empty: '[пустой]',
				deletedRecord: '[удален]',
			},
			productionOrderProcessPosition: {
				title: 'История позиций процессов',
				create: 'создал(а) матераил для процесса "{{processName}}"',
				updated: 'обновил(а) поля у материала "{{materialName}}" процесса "{{processName}}"',
				material: 'изменил(а) у процесса "{{processName}}" материал на',
				quantity: 'изменил(а) кол-во на ед. продукта у материала "{{materialName}}" процесса "{{processName}}" ',
				nomenclatureConsumption: 'создал(а) расход у материала "{{materialName}}" процесса "{{processName}}"',
				calibrationQuantity: 'изменил(а) приладку у материала "{{materialName}}" процесса "{{processName}}"',
				consumption: 'изменил(а) количество на списание у материала "{{materialName}}" процесса "{{processName}}"',
				deleted: 'удалил(а) материал у процесса "{{processName}}"',
				empty: '[пустой]',
				deletedRecord: '[удален]',
			},
			dialog: {
				close: 'закрыл(а) диалог',
				open: 'открыл(а) диалог',
				spamOn: 'поменил(а) диалог как спам',
				spamOff: 'удалил(а) диалог из спама',
				deleted: '[удалено]',
				task: 'создал(а) задачу',
				order: 'создал(а) заказ',
				document: 'создал(а) документ',
				messages: 'Диалог',
				status: 'изменил(а) статус',
				employee: 'создал(а) сотрудника',
			},
			notMessages: 'Нет сообщений',
		},

		boardLists: 'Состояния задач',
		boardList: {
			title: 'Состояние задачи',
			plural: 'Состояния задач',
			new: 'Новое состояние задачи',
			name: 'Название состояния',
			selectListToMove: 'Куда перенести задачи из этого списка?',
			moveTo: 'Перенести задачи в ',
			choose: 'Выберите список',
			movingTasksTo: 'Идет перемещение задач в список: ',
			closing: 'Закрывающий',
			closingShort: 'Закрывающий',
			all: 'Все состояния задач',
			selected: 'Выбрано состояний',
			projectDefault: 'По умолчанию в проекте',
			default: 'Статус новой задачи',
			delete: 'Удалить состояние',
			deleteInvalidMsg: 'Невозможно удалить, пока есть задача с данным состоянием',
			search: 'Поиск состояния',
		},
		projectLists: 'Состояния проектов',
		projectListsLabels: {
			beginEdit: 'Редактировать',
			endEdit: 'Сохранить',
			countTasks: 'Количество задач',
		},
		projectList: {
			title: 'Состояние проекта',
			plural: 'Состояния проекта',
			new: 'Новое состояние проекта',
			name: 'Название состояния',
			closing: 'Закрывающий',
			default: 'По умолчанию',
			delete: 'Удалить состояние',
			all: 'Все состояния проектов',
			selected: 'Выбрано состояний',
			deleteInvalidMsg: 'Невозможно удалить, пока есть проекты с данным состоянием',
		},
		taskLabel: {
			title: 'Метка задачи',
			labels: 'Метки задачи',
			plural: 'Метки задач',
			create: 'Новая метка задачи',
		},
		file: {
			uploadFile: 'Загрузить файл',
			uploadImage: 'Загрузить изображение',
			uploadStart: 'начинаю загрузку',
			uploadError: 'Ошибка загрузки',
			fileTooBig: 'Слишком большой файл',
			uploading: 'загрузка',
			uploadComplete: 'загрузка завершена',
			notSelected: 'файл не выбран',
			willBeUploaded: 'Будет загружен',
			wrongType: 'Неверный тип файла',
			moveOrSelectFile: 'Выберите или перетащите файл',
		},
		validate: {
			empty: 'Пустое значение',
		},
		kanban: {
			searchHint: 'Поиск по номеру, названию, Ф.И.О., меткам',
			orderByPriority: 'По умолчанию',
			orderByUpdate: 'По дате обновления',
			orderByDueDate: 'По дате дедлайна',
			orderByUpdateInfo: 'При сортировке по дате обновления\\nперетаскивать задачу можно только вверх списка',
			orderByDueDateInfo: 'При сортировке по дате дедлайна\\nперетаскивать задачи нельзя',
		},
		gantt: {
			showTasks: 'Показывать список задач',
			start: 'Начало',
			end: 'Конец',
			duration: 'Продолжительность',
			day: 'день',
			days: 'дней',
			day234: 'дня',
			noTasksWithDeadlines: 'Нет задач с заполненными сроками',
			name: 'Наименование',
		},
		analytics: {
			title: 'Аналитика',
			plural: 'Аналитика',
			projects: 'По проектам',
		},
		comment: {
			confirmTitle: 'Отмена',
			confirmDescription: 'Введенные изменения в комментарий будут утерены. Подтверждаете?',
			confirmButtonText: 'Отменить',
			delete: 'Удалить комментарий',
			title: 'Комментарий',
			postTitle: 'Написать комментарий',
			placeholder: 'Текст комментария',
			post: 'Отправить',
			confirmDelete: 'Удалить комментарий?',
		},
		filter: {
			all: 'все',
			none: 'нет',
			equals: 'равно',
			notEquals: 'не равно',
			greater: 'больше чем',
			greaterOrEqual: 'больше или равно',
			less: 'меньше чем',
			lessOrEqual: 'меньше или равно',
			contains: 'содержит',
			doesNotContain: 'не содержит',
			startsWith: 'начинается с',
			endsWith: 'заканчивается на',
			plural: 'Фильтры',
			apply: 'Применить',
			reset: 'Сбросить все',
		},
		ui: {
			theme: 'Тема',
			themes: {
				default: 'По умолчанию',
				dark: 'Тёмная',
			},
		},
		sales: {
			title: 'Продажи',
		},
		feature: {
			title: 'Характеристика',
			plural: 'Характеристики',
			create: 'Создать характеристику',
			dataType: 'Тип данных',
			featureType: 'Тип характеристики',
			values: 'Значения списка',
			string: 'Текст',
			number: 'Число',
			boolean: 'Да / Нет',
			list: 'Список',
			required: 'Обязательное',
			weight: 'Порядок',
			products: 'Виды продукции',
			product: 'Продукт',
			variant: 'Вариация продукта',
			null: '-',
			valuesPlural: 'Значения',
		},
		priceList: {
			title: 'Прайс-лист',
			plural: 'Прайс-листы',
			description: 'Описание прайс-листа',
			name: 'Название прайс-листа',
			start: 'Начало действия',
			end: 'Конец действия',
			period: 'Период действия',
			positions: 'Позиций',
			total: 'Всего прайс-листов',
			catalog: 'Каталог',
			betaTitle: 'Прайс-лист (бета)',
			new: 'Новый прайс-лист',
			create: 'Новый прайс-лист',
			info: 'Информация',
			copy: 'Копировать прайс-лист',
		},
		priceCategory: {
			title: 'Категория прайс-листа',
			plural: 'Категории прайс-листа',
			create: 'Новая категория',
			features: 'Характеристики',
			priceCalculation: 'Рассчет стоимости',
			positions: 'Позиции',
			moveTo: 'Переместить в категорию',
			confirmMove: 'Переместить?',
			root: 'Корень',
			name: 'Название',
			new: 'Новая категория',
			enterName: 'Введите название',
		},
		product: {
			select: 'Выберите продукт из списка',
			addCategories: 'Добавьте категории в прайс-лист',
			title: 'Продукт',
			plural: 'Продукты',
			create: 'Новый продукт',
			info: 'Общая информация',
			name: 'Название',
			description: 'Описание',
			type: 'Тип продукта',
			category: 'Категория',
			variant: 'Вариация',
			addCharacteristic: 'Добавить характеристику',
			characteristic: 'Характеристика',
			nomenclatures: 'Номенклатура',
			createVariant: 'Создать вариацию',
			createNomenclature: 'Создать номенклатуру',
			deleteVariant: 'Удалить вариацию',
			nomenclaturesCount: 'Номенклатур',
			confirmDeleteVariant: 'Удалить вариацию?',
			variantImage: 'Изображение вариации',
			amountRanges: 'Диапазоны количества для образования цен',
			features: 'Характеристики',
			new: 'Новый продукт',
			goBackToPriceList: 'Вернуться в прайс-лист',
			addDescription: 'Добавьте описание к продукту',
			amountRange: {
				errorAdjacentRange: 'Не соответствие значений c соседними диапазонами',
				errorCurrentRange: 'Проверьте значения у текущего диапазона',
			},
			techMap: {
				title: 'Технологические карты',
				create: 'Создать технологическую карту',
				goToTechMap: 'Перейти к технологической карте',
			},
			reserved: 'Резерв',
			warehouse: 'Склад',
			sendToProduction: 'Отправить в производство',
			priceList: 'Прайс-лист',
			nomenclature: 'Номенклатура',
		},
		productCategory: {
			title: 'Категория продукции',
			plural: 'Категории продукции',
			add: 'Добавить категорию',
			addBefore: 'Добавить перед',
			addAfter: 'Добавить после',
			addChild: 'Добавить подкатегорию',
		},
		pricePosition: {
			title: 'Позиция прайс-листа',
			plural: 'Позиции прайс-листа',
			amount: 'Количество',
			from: 'от',
			to: 'до',
			amountFrom: 'Количество от',
			amountTo: 'Количество до',
			price: 'Стоимость',
			priceForOne: 'Цена за шт.',
			copyPrice: 'Скопировать',
			total: 'Общая сумма',
			name: 'Наименование позиции',
			nameShort: 'Наименование',
		},
		position: {
			add: 'Добавить позицию',
			delete: 'Удалить позицию',
			deleted: 'Позиция удалена',
			addMaterial: 'Добавить материал',
		},
		userLogs: {
			create: 'создал(а)',
			update: 'обновил(а)',
			delete: 'удалил(а)',
			models: {
				task: 'Задачу',
				project: 'Проект',
				order: 'Заказ',
				productionprocess: 'Операцию',
				nomenclature: 'Номенклатуру',
				nomenclaturemovement: 'Движение',
				warehouse: 'Склад',
				productionorder: 'Наряд',
				productionworkshop: 'Цех',
				processequipment: 'Оборудование',
				material: 'Материалы',
				techmap: 'Тех карту',
				document: 'Документы',
				contragent: 'Контрагента',
				user: 'Пользователя',
				role: 'Роль',
				acl: 'Право',
			},
			modules: {
				management: 'Управление',
				sales: 'Продажи',
				finance: 'Финансы',
				storehouse: 'Склад',
				manufacture: 'Производство',
				documents: 'Документооборот',
				contragents: 'Контрагенты',
				admin: 'Администрирование',
			},
		},
		process: {
			title: 'Процесс',
			add: 'Новый процесс',
			plural: 'Процессы',
			position: 'Позиция',
			new: 'Новый процесс',
			addName: 'Введите название процесса',
			noPositionCategory: 'Не указана категория позиции',
			selectPosition: 'Выбрать позицию заказа',
			delete: 'Удалить процесс',
			addDescription: 'Добавьте описание к процессу',
			newProcess: 'Новый процесс',
			workshop: {
				title: 'Цех',
				plural: 'Цеха',
				new: 'Для добавления склада цеха необходимо сохранить запись',
				search: 'Поиск цеха',
				warehouseLabel: 'Склады',
				placeholder: 'Выберите из списка',
				warehouseCount: 'Количество складов',
			},
			targetWorkshop: 'Цех назначения',
			equipment: {
				create: 'Новое оборудование',
				title: 'Оборудование',
				invNumber: 'Инв.номер',
				selectWorkshops: 'Выберите цех',
				selectedWorkshops: 'Выбрано цехов:',
			},
			operatorsNumber: 'Количество операторов',
			adjustmentsNumber: 'Количество приладок',
			workingTime: 'Время работы',
			addMaterial: 'Добавить материал',
			readyTime: 'Время готовности',
			factTime: 'Фактическое время',
			saved: 'Процесс сохранен',
			deleted: 'Процесс удален',
		},
		processEquipment: {
			title: 'Оборудование',
			count: 'Количество оборудования',
			new: 'Новое оборудование',
		},
		material: {
			consumption: 'Материал списан со склада',
			transfered: 'Материал перемещен',
			measure: 'Производственные единицы измерения',
			delete: 'Удалить материал',
			deleted: 'Материал удален',
			all: 'Все материалы',
			add: 'Новый материал',
			group: 'Группа метериалов',
			title: 'Материал',
			workshop: 'Цех',
			position: 'Позиция',
			plural: 'Материалы',
			name: 'Название',
			feature: 'Характеристика',
			format: 'Формат',
			units: 'Еденица измерения',
			copiesNumber: 'Количество на тираж',
			adjustmentsNumber: 'Количество на приладку',
			totalNumber: 'Количество общее',
			count: 'Количество материалов',
			selectPosition: 'Выбрать позицию заказа',
			noPositionCategory: 'Не указана категория позиции',
			type: {
				add: 'Новый тип материала',
				title: 'Тип материала',
				plural: 'Типы материалов',
				materials: 'Материалы типа',
				new: 'Для добавления материалов к типу необходимо сохранить запись',
			},
			remains: 'Остатки материала',
			errors: {
				notExist: 'Материала с таким id не найдено',
			},
		},
		technologyRoadmap: {
			title: 'Технологическая карта',
		},
		order: {
			totalShort: 'Сумма',
			totalOrders: 'Всего заказов',
			prepaidValue: 'Сумма аванса',
			name: 'Название заказа',
			categoriesAndAmount: 'Категория - количество',
			categoryNames: 'Категория',
			categoryAmounts: 'Количество',
			delete: 'Удалить заказ',
			newNameOrder: 'Введите название заказа',
			inputInvoiceNumber: 'Введите номер счета',
			inputContractNumber: 'Введите номер договора',
			copied: 'Копия заказа создана',
			descrName: 'Описание заказа',
			amount: 'Количество',
			new: 'Новый заказ',
			report: 'Отчет',
			document: 'Документ',
			editReport: 'Редактировать отчет',
			downloadReport: 'Скачать отчет',
			count: 'Кол-во заказов',
			noOrders: 'Заказы не найдены',
			category: 'Категория',
			defaultListError: 'Не выставлено состояние заказа по умолчанию',
			copy: 'Копировать заказ',
			garant: 'Гарантия',
			addGarant: 'Добавить гарантию',
			getprepaid: 'Получен',
			title: 'Заказ',
			plural: 'Заказы',
			all: 'Все заказы',
			number: 'Номер',
			n: '№',
			description: 'Описание',
			comment: 'Комментарий',
			updatedAt: 'Заказ обновлен',
			info: 'Информация о заказе',
			infoShort: 'Информация',
			positions: 'Состав заказа',
			positionsCount: 'Позиций',
			total: 'Стоимость заказа',
			totalAndDebt: 'Общая стоимость с учетом пени',
			price: 'Стоимость',
			client: 'Контрагент',
			selectClient: 'не выбран',
			invoiceNumber: 'Номер счета',
			invoiceDate: 'Дата счета',
			contractNumber: 'Номер договора',
			contractDate: 'Дата договора',
			paidStatus: 'Статус оплаты',
			paid: 'Оплачено',
			notPaid: 'Не оплачено',
			status: 'Статус заказа',
			manager: 'Менеджер',
			managers: 'Менеджеры',
			addDescription: 'Добавить описание',
			addComment: 'Добавить комментарий',
			startDate: 'Дата начала',
			dueDate: 'Дедлайн',
			endDate: 'Дата выдачи',
			startDateFrom: 'Дата начала, с',
			startDateTo: 'Дата начала, по',
			endDateFrom: 'Дата выдачи, с',
			endDateTo: 'Дата выдачи, по',
			mode: 'Вид',
			priceFrom: 'Стоимость, от',
			priceTo: 'Стоимость, до',
			confirmClientChange: 'В данном заказе есть контактные лица предыдущего контрагента. При смене контрагента они будут удалены.',
			gotoClient: 'Перейти к контрагенту',
			removeContact: 'Убрать из контактных лиц заказа?',
			searchHint: 'Поиск по номеру заказа, контрагенту, менеджеру, номеру счета',
			prepaid: 'Аванс',
			passport: 'Паспорт заказа',
			technologyRoadmap: 'Технологическая карта',
			dueDatePaid: 'Срок платежа',
			datePaid: 'Дата платежа факт',
			percentDebt: 'Процент пени в день',
			debt: 'Пени итого',
			history: {
				dueDate: 'изменил(а) дедлайн заказа на',
				dueDateNull: 'убрал(а) дедлайн заказа',
				old: 'Было',
				new: 'Стало',
				editPositionLog: 'изменил(а) позицию заказа',
				addPositionLog: 'добавил(а) позицию заказа',
				dueDatePaid: 'изменил(а) срок платежа',
				datePaid: 'изменил(а) дату платежа факт',
				percentDebt: 'изменил(а) процент пени в день',
				addAttachment: 'добавил(а) документ',
				addContactPerson: 'добавил(а) контактное лицо',
				removeContactPerson: 'удалил(а) контактное лицо',
				changeContragent: 'изменил(а) контрагента',
				addLabel: 'добавил(а) метку',
				removeLabel: 'удалил(а) метку',
				removeAttachment: 'удалил(а) документ',
				title: 'История заказа',
				created: 'создал(а) заказ',
				invoiceNumber: 'изменил(а) номер счета',
				invoiceDate: 'изменил(а) дату счета',
				contractNumber: 'изменил(а) номер договора',
				contractDate: 'изменил(а) дату договора',
				paid: 'изменил(а) статус оплаты на',
				paidYes: 'Оплачено',
				paidNo: 'Не оплачено',
				status: 'изменил(а) статус заказа на',
				newPosition: 'добавил(а) позицию заказа',
				deletedPosition: 'удалил(а) позицию заказа',
				newWaybill: 'добавил(а) накладную',
				deletedWaybill: 'удалил(а) накладную',
				total: 'итоговая стоимость изменилась: ',
				appointed: 'назначил(а) менеджера: ',
				appointedSelf: 'назначил(а) менеджером себя',
				appointedNull: 'убрал(а) назначение менеджера',
				client: 'изменил(а) контрагента: ',
				startDate: 'изменил(а) дату начала:',
				endDate: 'изменил(а) дату конца:',
				prepaidValue: 'обновил(а) размер аванса: ',
				prepaid: 'изменил(а) статус аванса на',
				prepaidYes: 'Получен',
				prepaidNo: 'Не получен',
				newProcess: 'добавил(а) процесс заказа',
				deletedProcess: 'удалил(а) процесс заказа',
				newMaterial: 'добавил(а) материал заказа',
				deletedMaterial: 'удалил(а) материал заказа',
				deletedPositionAttachment: 'удалил(а) из позиции заказа файл(ы)',
				addedPositionAttachment: 'добавил(а) в позицию заказа файл(ы)',
				closeOrder: 'закрыл(а) заказ',
				sendToProduction: 'позиция {{position}} отправлена в производство',
				from: 'из заказа "{{orderName}}"',
			},
			waybill: {
				tabname: 'Накладная',
				name: 'Наименование',
				gramms: 'Размер листа',
				count: 'Кол. Листов',
				density: 'Плотность',
				gaveOut: 'Выдал',
				defect: 'Брак',
				return: 'Возврат',
				group: 'Группа номенклатуры',
			},
			contragent: {
				new: 'Новый контрагент',
				fastCreate: `Данная форма используется для упрощенного создания контрагента.
				Для редактирования дополнительных сведений, перейдите в карточку контрагента в разделе "Контрагенты",
				или воспользуйтесь ссылкой "Перейти к контрагенту"`,
			},
			position: {
				fastCreate:
					'Созданная категория будет являться родительской, для создания дочерних категорий воспользуйтесь разделом "Прайс-лист"',
			},
			cancelOrder: 'Отменить заказ',
			confidentСancelOrder: 'Вы уверены что хотите отменить заказ?',
			confidentСloseOrder: 'Вы уверены что хотите завершить заказ?',
			closeOrder: 'Завершить заказ',
			order_one: 'заказ',
			order_few: 'заказа',
			order_many: 'заказов',
			calendarHint: 'Заказы без назначенной даты выдачи',
			clearPositions: 'Очистить позиции',
			mine: 'Мои заказы',
		},
		viewProjectList: {
			plural: 'Список состояний проекта',
		},
		billing: {
			total: 'Итого с учетом скидки',
			tariff: 'Тариф',
			onChangeTariff: 'Изменить тариф',
			onChange: 'Перейти',
			changing: 'Изменение',
			active: 'Активен',
			stopped: 'Остановлен',
			confirmChanging: 'Подтвердите изменения тарифе.',
			storage: 'Дисковое пространство',
			changeTariffMes: 'Тариф ожидает оплаты...',
			cancelPayment: 'Отменить оплату',
			title: 'Биллинг',
			cost: 'Стоимость',
			currency: 'Валюта',
			expireDate: 'Дата завершения',
			status: 'Статус',
			usedSpace: 'Использовано',
			extensionCost: 'Продлить',
			balance: 'Баланс',
			notEnoughMoney: 'Недостаточно средств, пополните счет на',
			unknownError: 'Неизвестная ошибка',
			extend: 'Пополнить',
			confirmQuestion: 'Средства сразу спишутся с лицевого счета в биллинге. Продолжить?',
			payment: 'Оплата',
			pay: 'Оплатить',
			failedPayTitle: 'Внимание',
			successPayTitle: 'Поздравляем',
			failedPayDescription: 'Возникла ошибка, возможно на счете не хватает средств',
			successPayDescription: 'Действие выполнено успешно',
			payClose: 'Закрыть',
			errors: {
				authError: 'Ошибка авторизации',
			},
		},
		orderList: {
			title: 'Состояние заказа',
			plural: 'Состояния',
			create: 'Новое состояние',
			name: 'Название состояния',
			default: 'По умолчанию',
			closing: 'Закрывающий',
			roleNames: 'Роли',
			role: 'Роль',
		},
		orderTechPassport: {
			bindingType: 'Тип переплета',
			textColorfulness: 'Красочность текста',
			publicationFormat: 'Формат издания',
			pastingColorfulness: 'Красочность вклейки',
			cutFormat: 'Формат до обреза',
			colorfulFlyleaf: 'Красочность форзаца',
			formatAfterCutting: 'Формат после обреза',
			coverColorfulness: 'Красочность обложки',
			pagesNumber: 'Кол-во страниц (текст + вкл)',
			filmType: 'Тип пленки',
			textVolume: 'Объем текста',
			embossing: 'Тиснение',
			pasteVolume: 'Объем вклейки',
			varnishing: 'Лакирование',
			additionalInformation: 'Дополнительные сведения',
		},
		orderPosition: {
			discount: 'Скидка, руб',
			copy: 'Копировать позицию',
			title: 'Позиция заказа',
			add: 'Добавить позицию',
			plural: 'Позиции заказа',
			amount: 'Количество',
			price: 'Стоимость',
			priceForOne: 'Цена за шт.',
			category: 'Категория',
			pricePositionName: 'Наименование позиции',
			selectedPricePositionName: 'Выбранная позиция',
			save: 'Сохранить',
			lookingForMatches: 'Поиск совпадений',
			matchingPricePositions: 'Найдено совпадений',
			emptyAmount: 'Показаны совпадения без учёта количества. Чтобы скопировать цену, укажите количество в позиции заказа.',
			noMatches: 'Совпадений нет',
			noCategorySelected: 'Не выбрана категория',
			selectCategoryToEditFeatures: 'Выберите категорию, чтобы редактировать характеристики.',
			noFeaturesSetForCategory: 'Для данной категории не заданы характеристики.',
			gotoCategory: 'Перейти в категорию',
			discountPercent: 'Скидка, %',
			discountDirect: 'Скидка, руб.',
			final: 'Итоговая стоимость',
			members: 'Участники заказа',
			comment: 'Комментарий',
			attachmentsLink: 'Ссылка на материалы',
			membersShort: 'Участники',
			addMember: 'Добавить участника',
			addParameter: 'Добавить параметр',
			parameters: 'Дополнительные параметры',
			size: 'Размеры',
			unitPrice: 'Цена за ед.',
			delete: 'Удалить позицию',
			materialRef: 'Ссылка на материалы',
			willAbleAfterSave: 'Дополнительные данные будут доступы после сохранения',
			enterDiscount: 'Введите скидку',
			enterAmount: 'Введите количество',
			enterUnitPrice: 'Введите цену за ед.',
			enterSize: 'Введите размер',
			enterPrice: 'Введите стоимость',
			enterRef: 'Введите ссылку',
			addingProduction: 'Добавить в наряд',
			deleteConfirm: 'Удалить?',
			waybill: 'Накладная',
			reserved: 'Зарезервировано',
			reservedDate: 'Дата резерва',
			nomenclatureId: 'Номенклатура',
			warehouseId: 'Склад',
			nomenclature: {
				nameProduct: 'Наименование продукта',
			},
		},
		orderPositionParameter: {
			title: 'Параметр в позиции заказа',
			plural: 'Параметры в позиции заказа',
			new: 'Новый параметр в позиции заказа',
			delete: 'Удалить параметр',
		},
		orderPositionRole: {
			title: 'Роль в позиции заказа',
			plural: 'Роли в позиции заказа',
			new: 'Новая роль в позиции заказа',
			delete: 'Удалить роль',
		},
		sorting: {
			title: 'Сортировка',
			newFirst: 'Сначала новые',
			oldFirst: 'Сначала старые',
			priceAsc: 'Сначала недорогие',
			priceDesc: 'Сначала дорогие',
		},
		nomenclatureGroup: {
			all: 'Все категории',
			title: 'Категория номенклатуры',
			new: 'Новая категория номенклатуры',
			delete: 'Удалить категорию',
			plural: 'Категории номенклатуры',
			parent: 'Родительская категория',
		},
		enterpriseData: {
			title: '1С',
			sidebar: {
				submenu: '1C-Синхронизация',
				books: '',
				nodes: 'Узлы обмена',
				settings: 'Параметры',
				objects: 'Настройка объектов',
				history: 'История синхронизаций',
				journal: {
					title: 'Полученные сообщения',
					header: 'Журнал синхронизации',
					downloadFile: 'Скачать файл',
					goToBackVersion: 'Откатиться до этой версии',
				},
			},
			settings: {
				title: 'Параметры информационной базы',
			},
			importCategory: 'Импортировать категорию в прайс-лист',
		},
		default: 'По умолчанию',
		productionOrderStatus: {
			plural: 'Статусы наряда на производство',
			new: 'Новый статус',
			default: 'Открывающий',
			closing: 'Закрывающий',
		},

		contragents: {
			plural: 'Контрагенты',
			all: 'Все контрагенты',
			card: {
				title: 'Карточка контрагента',
				tabs: {
					info: 'Общая информация',
					staff: 'Сотрудники',
					staffNew: 'Новый сотрудник',
					bank: 'Банковские реквизиты',
					bankNew: 'Новые реквизиты',
					projects: 'Проекты',
					docs: 'Документы',
					payments: 'Платежи',
				},
				name: 'Наименование',
				phone: 'Телефон',
				email: 'E-mail',
				legalAddress: 'Юридический адрес',
				actualAddress: 'Фактический адрес',
				comments: 'Комментарий',
				basicInfo: 'Основные сведения',
				additionalInfo: 'Дополнительные сведения',
				personName: 'Фамилия, имя, отчество',
				personRole: 'Должность',
				inn: 'ИНН',
				bic: 'БИК',
				checkingAccount: 'Расчётный счёт',
				kpp: 'КПП',
				okato: 'ОКАТО',
				oktmo: 'ОКТМО',
				okved: 'ОКВЭД',
				ogrn: 'ОГРН',
				okpo: 'ОКПО',
				myCompany: 'Моя компания',
				bank: 'Банк',
				innExist: 'уже используется другим контрагентом',
				type: 'Тип контрагента',
			},
		},
		materialType: {
			plural: 'Типы материалов',
		},
		nomenclature: {
			materialMeasureRatio: 'Соотношение к ед. материала',
			onlyFavourites: 'Показать только избранные',
			showNegativeTotal: 'Показать отрицательные остатки',
			date: 'Дата добавления',
			favourite: 'Избранное',
			material: 'Материал',
			plural: 'Номенклатура',
			title: 'Номенклатура',
			position: 'Позиция номенклатуры',
			from: 'Откуда',
			to: 'Куда',
			quantity: 'Количество',
			measure: 'Единицы измерения',
			onlyNonZeroQuantity: 'Только ненулевые остатки',
			lastBalanceUpdate: 'Дата обновления',
			noGroups: 'Не удалось получить группы',
			total: 'Всего остатков',
			delete: 'Удалить номенклатуру',
			new: 'Добавить номенклатуру',
			storehouse: 'Склад',
			remains: 'Остатки по складам',
			group: 'Номенклатурная группа',
			selectNomenclature: 'Выбрать номенклатуру',
			errorName: 'Номенклатура с таким именем уже существует',
			isExistsName: 'Номенклатура с таким именем уже существует',
			isExistsArticle: 'Номенклатура с таким артикулом уже существует',
			hintFeature: 'Заполните хотя бы одну характеристику',
		},
		storehouse: {
			title: 'Склад',
		},
		operation: {
			title: 'Операция',
			plural: 'Операции',
			count: 'Количество операций',
			add: 'Новая операция',
			edit: 'Изменить операцию',
			notFound: 'Операции не найдены',
			withContragent: 'Операции с контрагентом',
			new: 'Новая операция',
			total: 'Всего операций',
			search: 'Поиск по сумме и описанию',
			warnings: {
				chooseBankAndDates: 'Нужно выбрать только один банковский счет, а так же даты начала и окончания выгрузки',
			},
			errors: {
				cantReadTextFile: 'Не удалось прочитать файл',
				fileWasNotSelected: 'Не выбран файл',
			},
			successNotifications: {
				importCompleted: 'Импорт успешно завершен',
			},
		},
		bankAccount: {
			name: 'Название',
			title: 'Банковский счет',
			plural: 'Банковские счета',
			selectBankAccount: 'Выберите банковский счет',
			allMyBankAccount: 'Все мои банковские счета',
			notSelected: 'Не выбран банковский счет',
			bank: 'Банк',
			balance: 'Баланс',
			checkingAccount: 'Расчетный счет',
			correspondentAccount: 'Корреспондентский. счет',
			bik: 'БИК',
		},
		cashFlow: {
			title: 'Cтатья ДДС',
			plural: 'Статьи ДДС',
			shortTitle: 'Статья',
			allCashFlow: 'Все статьи',
			selectCashFlow: 'Выберите статью',
			new: 'Новая статья ДДС',
			delete: 'Удалить статью',
		},
		contragent: {
			title: 'Контрагент',
			titleShort: 'Контра-ты',
			plural: 'Контрагенты',
			myCompany: 'Моя компания',
			myCompanies: 'Мои компании',
			delete: 'Удалить контрагента',
			dataAboutBoss: 'Данные о руководителе',
			new: 'Новый контрагент',
		},
		technologist: 'Технолог',
		currency: {
			title: 'Валюта',
			plural: 'Валюты',
			new: 'Новая валюта',
		},
		available: 'Доступно',
		productionOrder: {
			productAmount: 'Единиц продукта',
			logs: 'Журнал действий',
			nomenclatureMovementRecord: 'Запись прихода номенклатуры',
			sendToWarehouse: 'Отправить на склад',
			nomenclatureProduct: 'Номенклатура/Продукт',
			nomenclature: 'Наименование номенклатуры',
			warehouseTo: 'Склад итоговой продукции',
			pickWarehouseTo: 'Выберите склад',
			pickNomenclature: 'Выберите номенклатуру',
			pickProduct: 'Выберите продукт',
			removeProcesses: 'Удалить все процессы',
			removeProcessesMessage: 'Все процессы будут удалены и станет доступен выбор тех.карты. Продолжить?',
			pickWarehouse: 'Выберите склад',
			executorNotPicked: 'Исполнитель не выбран',
			pickExecutor: 'Выберите исполнителя',
			takeFromStorehouse: 'Взять со склада (номенклатура - склад)',
			removeTechMapMessage: 'Тех. карта и все процессы будут убраны. Продолжить?',
			applyTechMap: 'Применить тех.карту',
			removeTechMap: 'Очистить тех.карту и процессы',
			addProcess: 'Добавить процесс',
			techMapName: 'Наименование технологической карты',
			pickTechMap: 'Выберите технологическую карту',
			pickMeasure: 'Выберите единицу измерения',
			pickAmount: 'Введите количество',
			pickName: 'Введите наименование наряда',
			product: 'Наименование продукта',
			name: 'Наименование наряда',
			status: 'Статус наряда',
			pickStatus: 'Выберите статус наряда',
			technologist: 'Технолог',
			technologists: 'Технологи',
			pickTechnologist: 'Выберите технолога',
			menu: 'Производство',
			titleShort: 'Произв-во',
			mainInfo: 'Основная информация о наряде',
			movements: 'Перемещение',
			new: 'Новый наряд',
			plural: 'Наряды',
			title: 'Наряд',
			workshops: 'Цеха',
			equipments: 'Оборудование',
			process: 'Процессы',
			materials: 'Материалы',
			techMap: 'Технол. Карты',
			warehouses: 'Склады',
			startDate: 'Дата передачи',
			endDate: 'Дата завершения',
			productionOrder_one: 'наряд',
			productionOrder_few: 'наряда',
			productionOrder_many: 'нарядов',
			calendarHint: 'Наряды без назначенной даты завершения',
			processPosition: {
				calibrationQuantity: 'Приладка',
				calibrationQuantityHint:
					'Есть ненулевое значение приладки,\nно приладка отключена в настройках. \nобратитесь к администратору',
			},
			mine: 'Мои наряды',
			comment: 'Комментарий',
			toolbar: {
				showCancel: 'Показать отмененные',
			},
		},
		productionWorkshop: {
			create: 'Новый цех',
			add: 'Новый цех',
			title: 'Цех',
			plural: 'Цеха',
			noWorkshops: 'Нет цехов',
			all: 'Все цеха',
			selected: 'Выбрано цехов',
		},
		techMap: {
			forPcs: 'Технологическая карта за единицу',
			quantityShort: 'Кол-во на ед. продукта',
			productName: 'Название продукта',
			plural: 'Технологичкие карты',
			comment: 'Комментарий',
			author: 'Технолог',
			title: 'Технологическая карта',
			number: 'Номер',
			material: 'Материал',
			quantity: 'Количество',
			money: 'Деньги',
			addOperation: 'Добавить процесс',
			new: 'Новая тех.карта',
			materials: 'Количество материалов',
			processes: 'Количество процессов',
			equipments: 'Количество оборудования',
			delete: 'Удалить тех.карту',
			process: {
				title: 'Процесс',
				delete: 'Удалить процесс',
			},
			product: {
				create: 'Создать продукт',
				goToProduct: 'Перейти к продукту',
			},
		},

		warehouse: {
			create: 'Новый склад',
			title: 'Склад',
			all: 'Все склады',
			plural: 'Склады',
			add: 'Новый склад',
			address: 'Адрес',
			phone: 'Телефон',
			dopinfo: 'Дополнительная информация',
			remove: 'Удалить склад',
			new: 'Новый склад',
			createWarehouses: 'Создать склад',
			warehouseTitle: 'Название склада',
			contragent: 'Контрагент',
			responsible: 'Ответственный',
			warehouseFrom: 'Склад отправитель',
			warehouseTo: 'Склад получатель',
			nomenclatureRemains: 'Остатки номенклатуры',
			selectedWarehouses: 'Выбрано складов',
			allWarehouses: 'Все склады',
			selected: 'Выбрано складов',
			errors: {
				notExist: 'Склада с таким id не найдено',
			},
		},
		declinationOfNumber: {
			direction1: 'направление',
			direction2: 'направления',
			direction5: 'направлений',
			project1: 'проект',
			project2: 'проекта',
			project5: 'проектов',
			cashFlow1: 'статья',
			cashFlow2: 'статьи',
			cashFlow5: 'статей',
			contragent1: 'контрагент',
			contragent2: 'контрагента',
			contragent5: 'контрагентов',
		},
		measure: {
			title: 'Единица измерения',
			plural: 'Единицы измерения',
			new: 'Новая единица измерения',
			delete: 'Удалить единицу измерения',
		},
		contragentType: {
			title: 'Тип контрагента',
			plural: 'Типы контрагентов',
			new: 'Новый тип контрагента',
			name: 'Короткое название',
			fullName: 'Название',
			delete: 'Удалить тип',
		},
		nomenclatureBalanceAccount: {
			title: 'Счет баланса',
			plural: 'Счета баланса',
			new: 'Новый счет баланса',
			isAsset: 'Учитывать при рассчёте остатков',
			delete: 'Удалить счет',
		},
		nomenclatureMovement: {
			makeMaterialMovement: 'Переместить со склада на склад',
			makeMaterialConsumption: 'Списать материал со склада',
			materialQuantity: 'Количество в производственных единицах',
			consumptionRecord: 'Запись расхода {{count}} {{measure}}',
			transferRecord: 'Запись перемещения {{count}} {{measure}} с {{warehouse}}',
			title: 'Движения номенклатур',
			all: 'Всего движений номенклатур',
			new: 'Новое перемещение',
			plural: 'Движения номенклатур',
			income: 'Приход',
			cost: 'Расход',
			transfer: 'Перемещение',
			newMovement: 'Новое перемещение',
			editMovement: 'Редактировать перемещение',
			code: 'Номер',
			numberOfLine: 'Строка №',
			total: 'Сумма',
			price: 'Цена',
			currency: 'Валюта',
			errors: {
				emptyDate: 'Не заполнено поле дата',
				emptyWarehouseTo: 'Не заполнен склад получатель',
				emptyWarehouseFrom: 'Не заполнен склад отправитель',
				emptyNomenclature: 'Не заполнена номенклатура',
				emptyQuantity: 'Не заполнено количество',
			},
			dateFrom: 'Дата, с',
			dateTo: 'Дата, по',
			typeOfMovement: 'Тип движения',
			order: 'Заказ',
			type: 'Тип',
		},
		total: 'Всего',
		archiveAttachments: {
			download: 'Скачать архивом',
			preparation: 'Подготовка',
		},
		betaPopover: 'Данный раздел находится в разработке, можно пользоватся только в ознакомительных целях',
		catalog: {
			title: 'Каталог',
			goBack: 'Назад',
			showBy: 'Показывать по',
		},
		systemSetting: {
			title: 'Общие',
			enableRegistration: 'Разрешить самостоятельную регистрацию пользователей',
			maxFileSize: 'Максимальный размер, загружаемого файла',
		},
		documentHistory: {
			title: 'История документа',
			created: 'создал(а) документ',
			updated: 'обновил(а)',
			comment: 'комментарий',
			name: 'название',
			number: 'изменил(а) номер документа',
			total: 'изменил(а) суммарную цену',
			startDate: 'изменил(а) дату документа',
			endDate: 'изменил(а) дату закрытия документа',
			appointed: 'назначил(а) документ на',
			appointedSelf: 'назначил(а) документ на себя',
			appointedNull: 'убрал(а) назначение документа',
			status: 'перевел(а) документ в статус',
			removeStartDate: 'убрал(а) дату документа',
			removeEndDate: 'убрал(а) дату закрытия документа',
			type: 'изменил(а) тип документа',
			addAttachment: 'добавил(а) файл',
			removeAttachment: 'удалил(а) файл',
			addProject: 'добавил(а) проект',
			removeProject: 'удалил(а) проект',
			addContragent: 'добавил(а) конрагента',
			removeContragent: 'удалил(а) контрагента',
			addLabel: 'добавил(а) метку',
			removeLabel: 'удалил(а) метку',
			private: 'сделал(а) документ приватным',
			public: 'сделал(а) документ публичным',
			memberAdded: 'добавил(а) в участники документа ',
			memberSelfAdded: 'добавил(а) себя в участников документа',
			memberRemoved: 'убрал(а) из участников документа ',
			memberSelfRemoved: 'убрал(а) себя из участников документа',
		},
		paymentDirection: {
			title: 'Направление деятельности',
			plural: 'Направления деятельности',
			name: 'Наименование',
			code: 'Код',
			new: 'Новое направление',
			allPaymentDirections: 'Все направления',
		},
		userStatus: {
			new: 'Добавить статус',
		},
		paymentsTab: {
			empty: 'Платежей не найдено',
		},
		responsiblePersons: {
			title: 'Ответственные лица',
			notify: 'Уведомление пользователя',
		},
		productionLabel: {
			new: 'Новая метка',
		},
		notification: {
			settings: 'Настройки',
			save: 'Сохранить настройки',
			socket: {
				connectedUser: 'вошел(ла) в SmartERP',
			},
			task: {
				change: 'Обновление задачи',
				label: 'Метки',
				comment: 'Комментарии',
				user: 'Назначение ответственным',
				description: 'Описание задачи',
				attachment: 'Файлы',
			},
			emptyDefaultTechnologist:
				'Не заполнен технолог по умолчанию. Обратитесь к администратору или назначьте технолога в наряде вручную',
		},
		calendarRecords: {
			weekDays: {
				mon: 'пн',
				tue: 'вт',
				wed: 'ср',
				thu: 'чт',
				fri: 'пт',
				sat: 'сб',
				sun: 'вс',
			},
			hideClosed: 'Скрыть закрытые',
		},
		modelProperty: {
			additionalFields: 'Дополнительные поля',
			priority: 'Приоритет',
			task: 'Настраиваемые поля задачи',
			order: 'Настраиваемые поля заказа',
			plural: 'Настраиваемые поля',
			project: 'Настраиваемые поля проекта',
			isHidden: 'Скрытое поле',
			isUnique: 'Уникальное значение',
			isRequired: 'Обятазельное поле',
			hasIndex: 'Индекс',
			type: 'Тип',
			description: 'Описание',
			name: 'Название',
			code: 'Кодовое название на английском',
			new: 'Добавить поле',
			modelName: 'Название модели',
		},
		periods: {
			month: {
				january: 'январь',
				february: 'февраль',
				march: 'март',
				april: 'апрель',
				may: 'май',
				june: 'июнь',
				july: 'июль',
				august: 'август',
				september: 'сентябрь',
				october: 'октябрь',
				november: 'ноябрь',
				december: 'декабрь',
			},
		},
		passwordSettings: {
			title: 'Настройки пароля',
			minLength: 'Минимальная длина',
			requireNumber: 'Наличие цифр',
			requireUppercase: 'Наличие символов в верхнем регистре',
			requireLowercase: 'Наличие символов в нижнем регистре',
			requireSpecialChars: 'Наличие специальных символов',
			invalidRange: 'Значение дожно быть от {{min}} до {{max}}',
			invalidPasswordOptions: 'Включите хотя бы одну опцию сложности пароля',
			password: 'Пароль',
			passwordExpirationTime: 'Время действия пароля (количество дней)',
			countSavedPasswords: 'Количество сохраняемых паролей для запрета совпадения',
			countUniqueCharts: 'Минимальное количество измененных символов в пароле',
		},
		authenticationSettings: {
			title: 'Настройки аутентификации',
			triesLimitBeforeCaptcha: 'Число попыток входа до защиты CAPTCHA',
			triesLimitBeforeBlock: 'Число попыток входа до блокировки пользователя',
			daysNoActivityBeforeBlocked: 'Количество дней неактивности до блокировки пользователя',
		},

		reports: {
			plural: 'Отчеты',
			finance: {
				warnings: {
					noData: 'Данные для отчета не найдены',
				},
				operations: 'Операции',
				abcProfit: {
					title: 'ABC по прибыли',
					profitShare: 'Доля в прибыли %',
					profitShareCumulative: 'Доля в прибыли накопленная %',
					groupA: 'Группа A',
					groupB: 'Группа B',
				},
				materialMovement: {
					title: 'Движения материалов',
				},
				managerialEffectiveness: {
					title: 'Эффективность работы менеджера',
					revenue: 'Выручка',
					costprice: 'Себестоимость',
					profit: 'Маржа',
					profitability: 'Рентабельность',
				},
				AccountsReceivable: {
					title: 'Дебиторская задолженность',
					duedatepaid: 'Срок платежа',
					currentdate: 'Текущая дата',
					daysoverdue: 'Дней просрочки',
					manager: 'Менеджер',
				},
				salesDepartmentEfficiency: {
					title: 'Эффективность отдела продаж',
					indicator: 'Показатель',
					revenue: 'Выручка',
					revenueChange: '% изм. выручки',
					margin: 'Маржа',
					marginChange: '% изм. маржи',
					newClients: 'Новых клиентов',
					newClientsChange: '% изм. новых клиентов',
				},
				nonLiquidAssets: {
					title: 'Отчет по неликвидам',
					remain: 'Остаток',
					buyingAvgPrice: 'Средняя цена покупки',
					remainsCosts: 'Сумма остатков',
					averageSalesPerDay: 'Средние продажи в день',
					willBeSoldIn: 'Будет продано в течении',
					onDate: 'На дату',
					days: 'дней',
				},
				overconsumptionOfMaterials: {
					title: 'Отчет по перерасходу сырья',
					orderid: '№ Наряда',
					ordername: 'Наряд',
					materialname: 'Материал',
					quantityfororder: 'Количество на наряд',
					consumption: 'Использовано',
					overconsumption: 'Перерасход',
				},
			},
			management: {},
		},
		back: 'Назад',
		close: 'Закрыть',
		productionOrderProcess: {
			equipmentId: 'Оборудование',
			workshopId: 'Цех',
			warehouseId: 'Склад',
			executorId: 'Исполнитель',
			processId: 'Процесс',
			money: 'Стоимость',
			readyTime: 'Время готовности',
			factTime: 'Фактическое время',
		},
		productionOrderProcessPosition: {
			materialId: 'Материал',
			quantity: 'Кол-во на ед. продукта',
			calibrationQuantity: 'Приладка',
			consumption: 'Количество на списание',
		},
		mainDashboard: 'Сводка',
		byFilter: 'По фильтру',
		manualLocking: 'Ручная блокировка, пользователем "{{userShort}}" {{dateBlocked}}',
		video: 'Видео',
		support: 'Поддержка',
		socialNetwork: {
			title: 'Социальные сети',
			vk: {
				title: 'OAuth VK ID',
				vkApplicationId: 'ID приложения',
				vkClientSecret: 'Защищённый ключ',
			},
			yandex: {
				title: 'OAuth Яндекс ID',
				yandexApplicationId: 'Client ID',
				yandexClientSecret: 'Client secret',
			},
			google: {
				title: 'Google OAuth',
				googleApplicationId: 'Client ID',
				googleClientSecret: 'Client secret',
			},
			mail: {
				title: 'OAuth Mail',
				mailApplicationId: 'ID Приложения / Client ID',
				mailClientSecret: 'Секрет / Client Secret',
			},
		},
		ERROR_SOC_NET: 'Прозошла ошибка, попробуйте позже!',
		globalSearch: {
			placeholder: 'Глобальный поиск',
			notFound: 'Ничего не найдено',
			all: 'Везде',
		},
		twoFactor: {
			title: 'Двухфакторная аутентификация',
			'2fa': 'Способ двухфакторной аутентификация',
			send: {
				email: 'Включена двухфакторная аунтификация. На ваш Email выслано письмо для подтверждения аунтификации',
				telegram: 'Включена двухфакторная аунтификация. На ваш telegram отправлено сообщение для подтверждения аунтификации',
			},
			email: 'Электронная почта',
			telegram: 'Telegram',
		},
		SUCCESS_SEND_2FA: 'Сообщение успешно отправлено выбранным способом. Следуйте инструкции в сообщении.',
		ERROR_TWO_FACTOR: 'Ошибка авторизации',
		ERROR_2FA_DATA: 'Не валидные данные',
		ERROR_USER_NOTFOUND: 'Пользователь не найден',
		ERROR_2FA_TOKEN: 'Невалидный токен',
		dateRangePicker: {
			startDate: 'Дата начала',
			endDate: 'Дата конца',
			period: 'Период',
		},
		period: {
			title: 'Период',
			start: 'Начало периода',
			end: 'Конец периода',
			select: 'Период',
			day: 'День',
			week: 'Неделя',
			month: 'Месяц',
			quarter: 'Квартал',
			year: 'Год',
			everyday: 'Каждый день',
			everyweek: 'Каждую неделю',
			everymonth: 'Каждый месяц',
			lastDayOfMonth: 'Последний день месяца',
			dayOfMonth: 'числа каждого месяца`',
		},
		messages: {
			title: 'Сообщения',
		},
		dialog: {
			plural: 'Диалоги',
			source: 'Источник',
			nikeName: 'Имя (никнейм)',
			contact: 'Контакт',
			lastMessage: 'Текст сообщения',
			date: 'Дата',
			selectCategory: 'Выберите категорию',
			isSpam: 'Спам',
			isClosed: 'Закрыто',
			hideSpan: 'Скрыть спам',
			log: 'Лог',
			description: 'Описание',
			username: 'Имя пользователя',
			name: 'ФИО',
			email: 'E-mail',
			phone: 'Телефон',
			status: 'Статус',
			countRecords: 'Количество записей',
			employee: 'Сотрудник контрагента',
			createEmployee: 'Создать сотрудника',
		},
		postMessage: {
			placeholder: 'Сообщение',
			emptyMessage: 'Пустое сообщение',
			send: 'Отправить',
			confirmDescription: 'Введенные изменения в сообщении будут утерены. Подтверждаете?',
			errorSend: 'Не удалось отправить сообщение во внешнюю систему',
		},

		dataType: {
			boolean: 'Да/нет',
			string: 'Строка',
			number: 'Число',
			date: 'Дата',
			datetime: 'Дата и время',
			task: 'Задача',
			project: 'Проект',
			user: 'Пользователь',
			document: 'Документ',
			order: 'Заказ',
		},
		dialogStatus: {
			title: 'Статус диалога',
			plural: 'Статусы диалога',
			name: 'Название',
			color: 'Цвет',
			isDefault: 'Статус нового диалога',
			new: 'Новый статус',
			notFount: 'Не задаты статусы диалогов',
		},
		dialogSource: {
			title: 'Источник диалога',
			plural: 'Источники диалогов',
			label: {
				VK: {
					login: 'ID сообщества',
					secretKey: 'Ключ доступа сообщества',
				},
				TLG: {
					login: 'Имя бота',
					secretKey: 'Токен бота',
				},
				EMAIL: {
					login: 'Логин',
					secretKey: 'Пароль',
					url: 'Адрес IMAP сервера (<imapServer>:<port>)',
					urlOut: 'Адрес SMTP сервера (<imapServer>:<port>)',
				},
			},
			login: 'Имя пользователя',
			url: 'URL для запроса',
			secretKey: 'Секретный ключ',
		},
		agGrid: {
			floatingFilter: 'Фильтры под столбцами',
			templates: 'Шаблоны',
			loadTemplate: 'Применить шаблон',
			rewriteTemplate: 'Записать настройки в шаблон',
			templateLoaded: 'Шаблон "{{template}}" загружен',
			templateRewrited: 'Шаблон "{{template}}" перезаписан текущими настройками',
			templateSaved: 'Название шаблона "{{oldName}}" изменено на "{{template}}"',
			templateCreated: 'Шаблон "{{template}}" с текущими настройками сохранен',
			templateDeleted: 'Шаблон настроек "{{template}}" удален',
			namePlaceholder: 'Введите название',
			settings: 'Настройки',
			leftSidebar: 'Боковое меню слева',
			theme: 'Тема',
		},
	},
};
