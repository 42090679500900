import React, { createRef } from 'react';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AutoAnimate, Loader, ButtonGroup, NumberInput, SlideInput } from '@smartplatform/ui';
import { Button, NotPhone, Phone, ConfirmPopup } from 'components';
import { faChevronDown, faChevronUp, faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveModuleIcon from 'client/img/icons/active-module.svg';
import CoinsIcon from 'client/img/icons/coins.svg';

import classNames from 'classnames';
import store from 'client/store';
import { t } from 'i18next';
import './billing.scss';

const MODULES = [
	{ name: 'management', title: 'userLogs.modules.management' },
	{ name: 'sales', title: 'userLogs.modules.sales' },
	{ name: 'finance', title: 'userLogs.modules.finance' },
	{ name: 'storehouse', title: 'userLogs.modules.storehouse' },
	{ name: 'manufacture', title: 'userLogs.modules.manufacture' },
	{ name: 'documents', title: 'userLogs.modules.documents' },
];

const YEAR = { title: 'year', value: 12 };
const QUARTER = { title: 'quarter', value: 3 };
const MONTH = { title: 'month', value: 1 };


@observer
export class Billing extends React.Component {
	@observable info = {};
	@observable activePopup = false;
	@observable error = false;
	@observable isBilling = false;
	@observable modules = MODULES;
	@observable changeTariffPayment = null;
	@observable isPaidTariff = false;
	@observable isTariffChange = false;
	@observable extendError = null;
	@observable showConfirmForExtend = false;
	@observable showConfirmForModules = false;
	@observable isFailedPay = false;
	@observable isSuccessPay = false;
	@observable period = MONTH;
	@observable total = {};
	@observable discSpace = { start: 1, update: 0 };
	@observable isChangeSpace = false;
	@observable isLoading = false;
	@observable modulesPrice = null;

	refPanelBilling = createRef();
	refCloseBilling = createRef();

	constructor(props) {
		super(props);
		this.init();
		this.handleClickOutsideBilling = this.handleClickOutsideBilling.bind(this);
	}

	handleClickOutsideBilling(event) {
		if (this.refPanelBilling && this.refCloseBilling) {
			const isPanelBilling = this.refPanelBilling.current?.contains(event.target);
			const isCloseBilling = this.refCloseBilling.current?.contains(event.target);
			if (!isPanelBilling && !isCloseBilling) {
				this.closePopup();
			}
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutsideBilling);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutsideBilling);
	}

	init = async () => {
		this.isLoading = true;

		try {
			this.isBilling = (await store.model.User.isBilling()) || false;
		} catch (e) {
			this.isBilling = false;
		}

		if (this.isBilling) {
			const billingInfo = await store.model.User.fetchingBilling();
			if (billingInfo.error && billingInfo.error === 'authError') {
				this.error = 'authError';
				this.isLoading = false;
				return null;
			}

			this.info = billingInfo;
			this.modulesPrice = this.info.modulesPrice;

			const configTariffPayment = (await store.model.Config.find({ where: { code: 'changeTariffPayment' } }))?.[0]?.value;
			if (configTariffPayment) {
				this.changeTariffPayment = JSON.parse(configTariffPayment);
				this.isPaidTariff = (await store.model.User.isPaidTariff(
					{ id: this.changeTariffPayment.paymentId }
				)).isPaid;
			}

			this.discSpace = { start: +this.info.paidSpace, update: +this.info.paidSpace };

			this.modules = this.modules.map((module) => {
				const costText = this.info.modules && this.info.modules[module.name][1];
				let cost = 0;

				if (costText) {
					if (costText.includes('год')) {
						cost = parseFloat(costText.match(/\d+/)) / 12;
					} else if (costText.includes('квартал')) {
						cost = parseFloat(costText.match(/\d+/)) / 3;
					} else {
						cost = parseFloat(costText.match(/\d+/));
					}
					return { ...module, checked: this.info.modules[module.name][0] || false, cost };
				}

				return module;
			});

			this.total = {
				year: this.info.totalArr && parseFloat(this.info.totalArr[4]['$']),
				quarter: this.info.totalArr && parseFloat(this.info.totalArr[2]['$']),
				month: this.info.totalArr && parseFloat(this.info.totalArr[1]['$']),
			};
		}

		this.isLoading = false;
	};

	handleConfirm = (prop, value) => () => (this[prop] = value);

	onTogglePopup = () => (this.activePopup = !this.activePopup);

	extension = async () => {
		this.showPopup = false;
		const res = await store.model.User.extendTariff({ elid: this.info.elid, period: this.period.value });

		if (res.status === 'error' && res.type === 'not_enough_money') {
			const { url } = await store.model.User.orderExtension({ elid: this.info.elid, period: this.period.value });

			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank';
			link.click();
			link.remove();
		} else if (res.status === 'error') {
			this.extendError = { mes: t('billing.notEnoughMoney') };
		} else {
			this.isSuccessPay = true;
		}

		this.showConfirmForExtend = false;
		this.init();
	};

	isChangesModules = () => {
		return this.modules.some((mod) => {
			if (!(mod.checked === this.info.modules[mod.name][0])) {
				return true;
			}
		});
	};

	handleCheckbox = (module) => async () => {
		const target = this.modules.find((mod) => mod.name === module);
		if (target.name === 'management' && target.checked) return null;

		target.checked = !target.checked;

		if (!this.info.isFree) {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					elid: this.info.elid,
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		} else if (this.isChangeSpace && this.discSpace.update > 1 && this.info.isFree) {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		}

		this.isTariffChange = this.isChangesModules();
		if (this.isTariffChange) this.period = MONTH;
	};

	editTariff = async () => {
		let result = null;
		this.showConfirmForModules = false;

		try {
			result = await store.model.User.editBilling({
				elid: this.info.elid,
				modules: this.modules,
				space: this.discSpace.update,
				isFree: this.info.tariffId === '19',
			});

			if (result.doc?.error?.['$type'] === 'not_enough_money') {
				const { url } = await store.model.User.orderExtension({ elid: this.info.elid, modules: this.modules, space: this.discSpace.update.toString(), isFree: this.info.tariffId === '19' });

				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank';
				link.click();
				link.remove();
			} else {
				this.isSuccessPay = true;
			}
		} catch (e) {
			console.log('Ошибка при добавлении модулей: ', e);
			this.isFailedPay = true;
		}

		this.init();
		this.isChangeSpace = false;
		this.isTariffChange = false;
	};

	onChangePeriod = (value) => () => (this.period = value);

	onChangeSpace = async (value) => {
		this.discSpace.update = value;

		this.isChangeSpace = this.discSpace.start !== this.discSpace.update;
		if (this.isChangeSpace) this.period = MONTH;
		if (this.isChangeSpace && !this.info.isFree) {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					elid: this.info.elid,
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		} else if (this.isChangeSpace && value > 1 && this.info.isFree) {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		} else if (!this.isChangeSpace) {
			this.total = {
				...this.total,
				editTariff: '0',
			};
		}
	};

	onChangeSpaceInput = async (value) => {
		if (value >= 1 && value <= 100) {
			this.discSpace = { ...this.discSpace, update: value };
		} else if (value < 1) {
			this.discSpace = { ...this.discSpace, update: 1 };
		} else if (value > 100) {
			this.discSpace = { ...this.discSpace, update: 100 };
		}

		this.isChangeSpace = this.discSpace.start !== this.discSpace.update;
		if (this.isChangeSpace) this.period = MONTH;
		if (this.isChangeSpace && !this.info.isFree) {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					elid: this.info.elid,
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		} else if (this.isChangeSpace && value > 1 && this.info.isFree) {
			this.total = {
				...this.total,
				editTariff: await store.model.User.getTotalOfAdding({
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			};
		} else if (!this.isChangeSpace) {
			this.total = {
				...this.total,
				editTariff: '0',
			};
		};
	};

	renderEditButton = () => {
		if (this.info.isFree && this.isChangeSpace) {
			return (
				<Button variant='primary' onClick={this.getChangeTariffAndPay}>
					{t('billing.pay')}
				</Button>
			);
		}

		return (
			<Button variant='primary' onClick={this.handleConfirm('showConfirmForModules', true)}>
				{this.info.isFree ? (t('change')) : t('billing.pay')}
			</Button>
		);
	};

	getChangeTariffAndPay = async () => {
		this.isPaidTariff = false;
		this.changeTariffPayment = true;
		const { url, paymentId } = await store.model.User.getChangeTariffAndPay({
			elid: this.info.elid,
			modules: this.modules,
			space: this.discSpace.update,
			isFree: this.info.isFree
		});

		await store.model.Config.batchUpdate([
			{
				code: 'changeTariffPayment',
				value: JSON.stringify({ paymentId, url }),
			}
		]);

		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		link.click();
		link.remove();

		this.init();
	}

	cancelPayment = async () => {
		await store.model.User.cancelPayment({ elid: this.info.elid });
		const changeTariffPayment = (await store.model.Config.find({ where: { code: 'changeTariffPayment' } }))[0];
		await changeTariffPayment.delete();
		this.isPaidTariff = true;
		this.isChangeSpace = false;
		this.total = {
			...this.total,
			editTariff: '0',
		};

		this.init();
	};

	setInstance = () => {
		if (this.props.getInstance) this.props.getInstance({ close: this.closePopup });
	};

	closePopup = () => {
		this.activePopup = false;
	};

	render() {
		const { onTogglePopup, activePopup, isBilling, info, extension, handleCheckbox, modules, editTariff, renderEditButton } = this;

		if (!isBilling) return null;

		let tariff = 'free';
		if ((this.isChangeSpace && this.discSpace.update > 1 && this.info.isFree) || (!this.info.isFree)) {
			tariff = 'smart';
		}

		const total = (
			this.isTariffChange || this.isChangeSpace
				? (this.total['editTariff'] || 0).toLocaleString('ru', {
					style: 'currency',
					currency: 'RUB',
				})
				: (this.total[this.period.title] || 0)?.toLocaleString('ru', {
					style: 'currency',
					currency: 'RUB',
				})
		);

		const isPaymentWaitingMode = this.changeTariffPayment && !this.isPaidTariff;
		// const isPaymentWaitingMode = true;

		const isFree = info.tariffName?.includes('Free');

		const buyingSpace = (this.discSpace.update - (isFree ? 2 : this.discSpace.start));
		const pricePerGigabyte = this.modulesPrice && parseFloat(this.modulesPrice[tariff].space[this.period.value]);
		const spacePrice = (this.discSpace.update > this.discSpace.start) ? (buyingSpace * pricePerGigabyte) : 0;


		const renderBillingButton = () => {
			if (this.isLoading) {
				return <Loader size={14} />;
			} else if (this.error) {
				return (<>
					<span>{t('billing.errors.' + this.error)}</span>
				</>)
			} else {
				return (<>
					<CoinsIcon className='billing-module-icon' />
					<span>{this.info.tariffName}</span>
					{activePopup ? (
						<span className='billing-arrow'>
							<FontAwesomeIcon icon={faChevronUp} />
						</span>
					) : (
						<span className='billing-arrow'>
							<FontAwesomeIcon icon={faChevronDown} />
						</span>
					)}
				</>)
			}
		}

		return (
			<div className='billing-wrapper'>
				<NotPhone>
					<div className='billing' ref={this.refCloseBilling}>
						<Button size='sm' onClick={() => !this.isLoading && !this.error && onTogglePopup()} className={'billing-btn ' + (this.error && 'error')}>
							{renderBillingButton()}
						</Button>
					</div>
				</NotPhone>
				<Phone>
					<div
						className={classNames('item-account-menu', { disable: this.isLoading })}
						onClick={() => !this.isLoading && onTogglePopup()}
					>
						<FontAwesomeIcon icon={faCoins} />
						{this.isLoading ? <Loader size={10} /> : info.tariffName}
					</div>
				</Phone>

				<AutoAnimate className={classNames('billing-popup-wrapper', { active: activePopup })} ref={this.setInstance}>
					{activePopup && (
						<div className='billing-popup' ref={this.refPanelBilling}>
							<div className='billing-header'>
								<div className='billing-header-statusline'>
									<span className='billing-tariff'>
										{t('billing.tariff')} {info.tariffName}
									</span>
									<span className={'billing-status ' + (info.status === 'Активен' ? 'billing-success' : 'billing-error')}>
										{(info.status === 'Активен' ? t('billing.active') : t('billing.close'))}
									</span>
								</div>
								<div className='billing-line-wrapper'>
									<div className='billing-line'>
										<span className='billing-line-title'>{t('billing.cost')}: </span>
										<span className='billing-line-value'>
											{parseFloat(info.cost.split('/')[0]).toLocaleString('ru', {
												style: 'currency',
												currency: 'RUB',
											})} / {info.cost.split('/')[1].replace('Месяц', t('month'))}
										</span>
									</div>
									{/* <div className='billing-line'>
										<span className='billing-line-title'>{t('billing.balance')}: </span>
										<span className='billing-line-value'>{parseFloat(info.balance).toLocaleString('ru', {
												style: 'currency',
												currency: 'RUB',
											})</span>
									</div> */}
									<div className='billing-line'>
										<span className='billing-line-title'>{t('billing.expireDate')}: </span>
										<span className='billing-line-value'>{info.expiredate}</span>
									</div>
								</div>
							</div>

							<div className='billing-body'>
								<div className='billing-buttons'>
									<ButtonGroup className='button-group'>
										{[YEAR, QUARTER, MONTH].map((period) => (
											<Button
												key={period.title}
												variant={this.period.title === period.title ? 'primary' : 'default'}
												disabled={isPaymentWaitingMode || this.isTariffChange || this.isChangeSpace}
												onClick={this.onChangePeriod(period)}
											>
												{t(period.title)}
											</Button>
										))}
									</ButtonGroup>
								</div>

								<div className='billing-modules'>
									{modules.map((module) => {
										let tariff = 'free';
										if (this.isChangeSpace && this.discSpace.update > 1 && this.info.isFree) {
											tariff = 'smart';
										} else if (!this.info.isFree) {
											tariff = 'smart';
										}

										return (
											<div
												className={classNames('billing-module', {
													'billing-module-active': module.checked,
													'billing-payment-waiting-mode': isPaymentWaitingMode,
												})}
												key={module.title}
												onClick={isPaymentWaitingMode ? null : handleCheckbox(module.name)}
											>
												{module.checked && <ActiveModuleIcon className='billing-module-icon' />}
												<div className='billing-module-text'>
													<span className='billing-module-title'>{t(module.title)}</span>
													<div className='billing-module-price-wrapper'>
														<span className='billing-module-price'>
															{this.modulesPrice && this.modulesPrice[tariff][module.name][this.period.value].toLocaleString('ru', {
																style: 'currency',
																currency: 'RUB',
															}) || 0}
														</span>
														{!(this.period.value === 1) && module.cost != 0 && (
															<del>
																<span className='billing-module-price-default'>
																	{(module.cost * this.period.value).toLocaleString('ru', {
																		style: 'currency',
																		currency: 'RUB',
																	})}
																</span>
															</del>
														)}
													</div>
												</div>
											</div>
										);
									})}
								</div>
								<div className='billing-spaces'>
									<div className='billing-spaces-line'>
										<span className='billing-spaces-line-title'>{t('billing.storage')}: </span>
										<span className='billing-spaces-line-value'>{spacePrice.toLocaleString('ru', {
											style: 'currency',
											currency: 'RUB',
										})}</span>
									</div>
									<NumberInput
										positiveOnly
										value={this.discSpace.update}
										onChange={this.onChangeSpaceInput}
										className='billing-spaces-input'
										disabled={isPaymentWaitingMode}
									/>
									<div className='billing-space-wrapper'>
										<div className='billing-space-line-wrapper'>
											<span>{this.discSpace.start} {t('gb')}</span>
											<span>100 {t('gb')}</span>
										</div>
										<SlideInput
											min={isFree ? 1 : 2}
											max={100}
											step={1}
											value={this.discSpace.update}
											onChange={debounce(this.onChangeSpace, 500, { leading: false, trailing: true })}
											disabled={isPaymentWaitingMode}
											className='billing-space-range'
										/>

										<div className='billing-space-wrapper'>
											<span>
												{info.hdd?.usedSpace || 0} МБ {t('billing.usedSpace')}
											</span>
										</div>
									</div>
								</div>
								<div className='billing-total'>
									{isPaymentWaitingMode ? (<>
										<div className='billing-list'>
											<div className='billing-total-line'>
												<div className='payment-waiting-text payment-waiting-link'>
													<a href={this.changeTariffPayment?.url || ''} className='link' target='_blank'>
														Вернуться к оплате
													</a>
												</div>
											</div>
											<div className='billing-total-line'>
												<div className='payment-waiting-text'>
													<Loader />
													<span>{t('billing.changeTariffMes')}</span>
												</div>
											</div>
										</div>

										<div className='billing-buttons'>
											<Button variant='default' onClick={this.cancelPayment}>
												{t('billing.cancelPayment')}
											</Button>
											<Phone>
												<Button variant='default' onClick={this.closePopup}>
													{t('close')}
												</Button>
											</Phone>
										</div>
									</>) : (<>
										<div className='billing-list'>
											<div className='billing-total-line'>
												<span>{t('billing.total')}: </span>
												<span>{total}</span>
											</div>
										</div>

										<div className='billing-buttons'>
											{this.isTariffChange || this.isChangeSpace ? renderEditButton() : (
												<Button variant='primary' onClick={this.handleConfirm('showConfirmForExtend', true)}>
													{t('billing.extensionCost')}
												</Button>
											)}
											<Phone>
												<Button variant='default' onClick={this.closePopup}>
													{t('close')}
												</Button>
											</Phone>
										</div>
									</>)}

									{this.extendError && (
										<div>
											<span className='error'>{this.extendError.mes}</span>
											<a onClick={this.extendError.replenish} className='extend-link'>
												{t('billing.extend')}
											</a>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{this.showConfirmForModules && (
						<ConfirmPopup
							onClose={() => {
								this.init();
								this.handleConfirm('showConfirmForModules', false)();
							}}
							title={this.info.isFree ? t('billing.changing') : t('billing.payment')}
							description={this.info.isFree ? t('billing.confirmChanging') : t('billing.confirmQuestion')}
							buttonText={this.info.isFree ? t('change') : t('billing.pay')}
							onConfirm={editTariff}
						/>
					)}
					{this.isFailedPay && (
						<ConfirmPopup
							onClose={this.handleConfirm('isFailedPay', false)}
							title={t('billing.failedPayTitle')}
							description={t('billing.failedPayDescription')}
							buttonText={t('billing.payClose')}
							onConfirm={this.handleConfirm('isFailedPay', false)}
						/>
					)}
					{this.isSuccessPay && (
						<ConfirmPopup
							onClose={this.handleConfirm('isSuccessPay', false)}
							title={t('billing.successPayTitle')}
							description={t('billing.successPayDescription')}
							buttonText={t('billing.payClose')}
							onConfirm={this.handleConfirm('isSuccessPay', false)}
						/>
					)}
					{this.showConfirmForExtend && (
						<ConfirmPopup
							onClose={this.handleConfirm('showConfirmForExtend', false)}
							title={t('billing.payment')}
							description={t('billing.confirmQuestion')}
							buttonText={t('billing.pay')}
							onConfirm={extension}
						/>
					)}
				</AutoAnimate>
			</div>
		);
	}
}
